import api from '@/api/fetch'
import {
  LastShiftGroupedTills,
  Movement,
  OpenMethodWithCurrentAmount,
  ShiftMovement
} from '@/types'

export function getOpenMethods(tillId: string) {
  return api
    .get<OpenMethodWithCurrentAmount[]>('/open-methods-with-current-amount', {
      tillId
    })
    .then(res => res.data)
}

export function getLatShiftMovements(tillId: string) {
  return api
    .get<ShiftMovement[]>('/reporting/last-till-shift-movements', {
      tillId
    })
    .then(res => res.data)
}

export function getLastShift(tillId: string) {
  return api
    .get('/last-shift', {
      tillId
    })
    .then(res => res.data)
}

export function getReportX() {
  return api.get('/reports/x').then(res => res.data)
}

export function getOpenShiftMovements(tillId: string) {
  return api
    .get<{ movements: Movement[] }>('/reports/open-shift-movements', {
      tillId
    })
    .then(res => res.data)
}

export function getLocationShiftsQuantity(data: {
  startDate: string
  endDate: string
}) {
  return api.get('/shifts/location-shifts-quantity', data).then(res => res.data)
}

export function getLastShiftGroupedTills() {
  return api
    .get<LastShiftGroupedTills>('/last-shift-grouped-tills')
    .then(res => res.data)
}
