<template>
  <full-screen :title="$t('couriers.title')" @close="router.go(-1)">
    <div
      v-if="couriersReport && Object.keys(couriersReport).length > 0"
      class="text-n-0 flex h-full p-4 gap-4"
    >
      <div class="flex flex-col basis-80 shrink grow-0">
        <div class="font-heading text-xl font-bold mb-7 mt-5">
          {{ $t('couriers.title') }}
        </div>
        <div class="overflow-y-auto flex flex-col gap-2">
          <div
            v-for="courierId in Object.keys(couriersReport)"
            :key="courierId"
            class="bg-n-700 px-4 py-6 font-heading font-medium text-base rounded-xl border border-transparent flex items-center justify-between cursor-pointer"
            :class="{
              'border-v-300': selectedCourier?.id === courierId
            }"
            @click="selectCourier(courierId)"
          >
            <div>{{ couriers[courierId].name }}</div>
            <div>
              {{ currency(courierTotal(courierId)) }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col overflow-hidden p-5 bg-n-700 font-body text-xs flex-1 grow shrink rounded-lg min-w-96 h-full"
      >
        <template v-if="selectedCourierReport && selectedCourier">
          <div
            class="flex items-center justify-between w-full font-heading text-xl font-bold"
          >
            <div>
              {{ selectedCourier.name }}
            </div>
            <div>
              {{ currency(courierTotal(selectedCourier.id)) }}
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4 mt-6">
            <div
              v-for="method in Object.keys(selectedCourierReport.payments)"
              :key="method"
              class="rounded-xl py-3 px-3 bg-n-800"
            >
              <div class="flex items-center justify-between">
                <div>
                  {{ getPaymentMethodLabel(method) }}
                </div>
                <div class="text-g-500 font-heading text-xl font-bold">
                  {{ currency(selectedCourierReport.payments[method]) }}
                </div>
              </div>
            </div>
          </div>
          <div class="overflow-y-auto mt-6">
            <courier-report-table :report="selectedCourierReport.tabs" />
          </div>
          <div class="flex mt-auto justify-end gap-4">
            <l-button type="secondary" @click="startCourierReset()">
              {{ $t('couriers.reset-courier') }}
            </l-button>
            <l-button @click="printCourierReport(selectedCourier.id)">
              {{ $t('couriers.print-report') }}
            </l-button>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="flex items-center justify-center h-full">
      <empty-case
        :type="CourierImage"
        enforced-translation-key="couriers.empty"
      />
    </div>
  </full-screen>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, shallowRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { EmptyCase, LButton, useDialog } from '@last/core-ui/paprika'

import { getCourierReport, resetCourierReport } from '@/api/reports'
import CourierImage from '@/assets/courier.svg?raw'
import { useMoney } from '@/composables/useMoney'
import TicketPrinter from '@/integrations/printer/ticketPrinter'
import { useCouriersStore } from '@/store/couriers'
import { Courier, CouriersReport } from '@/types/courier'

import FullScreen from '../core/FullScreen.vue'
import CourierReportTable from './CourierReportTable.vue'

const { t } = useI18n()
const router = useRouter()
const couriersStore = useCouriersStore()
const { couriers } = storeToRefs(couriersStore)

const dialog = useDialog()
const { currency } = useMoney()

const couriersReport = shallowRef<CouriersReport | null>(null)
const selectedCourier = ref<Courier | null>(null)

onMounted(() => {
  updateCouriersReport()
})

const selectedCourierReport = computed(() => {
  return couriersReport.value![selectedCourier.value!.id]
})

async function updateCouriersReport() {
  couriersReport.value = await getCourierReport()
  const courierIds = Object.keys(couriersReport.value)
  if (courierIds.length > 0) {
    selectedCourier.value = couriers.value[courierIds[0]]
  }
}

function selectCourier(courierId: string) {
  selectedCourier.value = couriers.value[courierId]
}

function startCourierReset() {
  dialog({
    title: t('couriers.reset-courier'),
    content: t('couriers.reset-message', {
      courierName: selectedCourier.value!.name
    }),
    secondaryLabel: t('ctas.cancel'),
    onConfirm: () => resetCourier()
  })
}

function printCourierReport(courierId: string) {
  TicketPrinter.printCourierReport({
    ...couriersReport.value![courierId],
    total: courierTotal(courierId),
    courierName: couriers.value[courierId].name
  })
}

async function resetCourier() {
  const courierId = selectedCourier.value?.id
  if (!courierId) return
  await resetCourierReport(courierId)
  await updateCouriersReport()
}

function courierTotal(courierId: string) {
  if (!couriersReport.value![courierId]) {
    return 0
  }
  return Object.values(couriersReport.value![courierId].payments).reduce(
    (total, amount) => (total += amount),
    0
  )
}

function getPaymentMethodLabel(method: string) {
  switch (method) {
    case 'cash':
      return t('couriers.cash')
    case 'card':
      return t('couriers.card')
    default:
      return method
  }
}
</script>
