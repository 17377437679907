<template>
  <l-modal
    :title="$t('generate-invoice.send-invoice-info')"
    :button-text="$t('generate-invoice.send-invoice')"
    :button-enabled="emailIsValid"
    :full-height="false"
    @close="$emit('close')"
    @action="performAction"
  >
    <l-field :label="$t('invoice-modal.email-label')" mandatory>
      <l-input
        v-model="email"
        :placeholder="$t('invoice-modal.email-placeholder')"
      />
    </l-field>
  </l-modal>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { lastUtils } from '@last/core'
import { LField, LInput, LModal } from '@last/core-ui/paprika'

import { useNotifications } from '@/composables/useNotifications'
import sync from '@/sync/service'

const { locale, t } = useI18n()

const { notifySuccess } = useNotifications()

const emit = defineEmits<{
  close: []
}>()

const props = defineProps<{
  billId: string
}>()

const email = ref('')
const emailIsValid = computed(() => {
  return !!email.value && lastUtils.isEmail(email.value)
})

function performAction() {
  sync.record('sendInvoiceEmailRequested', {
    billId: props.billId,
    email: email.value,
    locale: locale.value
  })
  notifySuccess({
    title: t('generate-invoice.invoice-sent-successfully')
  })
  emit('close')
}
</script>
