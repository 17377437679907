<template>
  <div class="flex flex-row">
    <div
      v-if="showFloorplanButton"
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': selectedTab === 'floorplan'
      }"
      @click="emit('update:selectedTab', 'floorplan')"
    >
      <l-icon size="medium" name="floorplan" />
    </div>
    <div
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': selectedTab === 'delivery'
      }"
      @click="emit('update:selectedTab', 'delivery')"
    >
      <l-icon size="medium" name="bike" />
    </div>
    <div
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': selectedTab === 'takeAway'
      }"
      @click="emit('update:selectedTab', 'takeAway')"
    >
      <l-icon size="medium" name="take-away" />
    </div>
    <div
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': selectedTab === 'all'
      }"
      @click="emit('update:selectedTab', 'all')"
    >
      <l-icon size="medium" name="cards" />
    </div>
    <div
      v-if="hasFastMode"
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="[selectedTab === 'fast' ? 'bg-y-500 text-n-0' : 'text-y-500']"
      @click="useFastMode().load()"
    >
      <l-icon size="medium" name="fast-pos" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computedAsync } from '@vueuse/core'
import { defineProps } from 'vue'

import { LIcon, useTracker } from '@last/core-ui/paprika'

import { useFastMode } from '@/store/fast-mode'

type Props = {
  selectedTab?: string | null
  showFloorplanButton?: boolean
}

withDefaults(defineProps<Props>(), {
  selectedTab: 'floorplan',
  showFloorplanButton: true
})

const emit = defineEmits(['update:selectedTab'])

const tracker = useTracker()

const hasFastMode = computedAsync(async () => {
  return await tracker.getFeatureFlag('fastpos')
}, false)
</script>
