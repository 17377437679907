<template>
  <div
    ref="element"
    :class="{ 'bg-n-600': sectionSelected }"
    class="border-b border-n-700"
  >
    <div
      class="flex items-center justify-between px-4 py-3 text-sm text-n-200 font-body cursor-pointer"
      @click="$emit('selectedSection')"
    >
      <div class="flex items-center gap-2">
        <l-radio
          :model-value="sectionSelected"
          @update:model-value="$emit('selectedSection')"
        />
        <span class="font-body text-base font-normal text-n-100">
          {{ sectionName }}
        </span>
      </div>
      <l-icon
        v-if="showRemove"
        name="delete"
        class="justify-end text-n-0 cursor-pointer"
        @click="$emit('onRemove')"
      />
    </div>

    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useTemplateRef } from 'vue'

import { LIcon, LRadio } from '@last/core-ui/paprika'

const element = useTemplateRef('element')

defineProps<{
  showRemove: boolean
  sectionName: string
  sectionSelected: boolean
}>()

defineEmits(['selectedSection', 'onRemove'])

defineExpose({
  element
})
</script>
