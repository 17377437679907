import { Buffer } from 'buffer'

import { lastUtils } from '@last/core'
import { useDialog } from '@last/core-ui/paprika'

import { useMoney } from '@/composables/useMoney'
import { useNotifications } from '@/composables/useNotifications'
import SocketFactory from '@/drivers/socket.js'
import i18n from '@/i18n'

const t = i18n.global.t

class Cashlogy {
  private config!: { ip: string; port: string }
  private inputAmountListener!: (amount: number) => void
  private totalAmountListener!: (amount: number) => void
  private cancelled: boolean = false

  private socket: any
  private initializing: any
  private initialized: boolean = false

  init({
    ip,
    port,
    inputAmountListener,
    totalAmountListener
  }: {
    ip: string
    port: string
    inputAmountListener: (amount: number) => void
    totalAmountListener: (amount: number) => void
  }) {
    this.config = { ip, port }
    this.inputAmountListener = inputAmountListener
    this.totalAmountListener = totalAmountListener
    this.initializing = this.initialize()
  }

  private getSocket(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.socket) {
        this.socket = SocketFactory.getSocket()
      }
      if (this.socket.closed) {
        this.socket.open(
          this.config.ip,
          this.config.port,
          () => {
            resolve(this.socket)
          },
          reject
        )
      } else {
        resolve(this.socket)
      }
    })
  }

  async sendCommand(command: string): Promise<string> {
    if (!this.config) throw new Error('Cashlogy without config')
    const socket = await this.getSocket()
    return new Promise((resolve, reject) => {
      socket.onData((response: string) => {
        resolve(Buffer.from(response).toString())
      })
      socket.write(Buffer.from(command), () => {}, reject)
    })
  }

  async sendCommandWithAutoInit(command: string) {
    await this.initialize()
    let response = await this.sendCommand(command)
    if (response.includes('#ER:ILLEGAL#')) {
      this.initializing = await this.initialize()
      response = await this.sendCommand(command)
    }
    return response
  }

  async initialize() {
    if (this.initialized) return
    if (this.initializing) return this.initializing
    const ipcRenderer = window.require('electron').ipcRenderer
    await ipcRenderer.invoke('cashlogy:init')
    const response = await this.sendCommand('#I#')
    if (response.includes('WR:LEVEL')) {
      this.notifyLowMoney()
    }
    this.initialized = true
  }

  async charge(amount: number) {
    await this.initializeAdmission()
    let inputAmount = 0
    while (inputAmount < amount && !this.cancelled) {
      inputAmount = await this.consultCurrentAdmissionAmount()
      this.inputAmountListener(inputAmount)
      await lastUtils.sleep(250)
    }
    const finalAmount = await this.finalizeAdmission()
    if (this.cancelled) {
      this.inputAmountListener(0)
      await this.dispense(finalAmount)
      this.cancelled = false
      return 0
    } else {
      if (finalAmount > amount) {
        await this.dispense(finalAmount - amount)
      }
      this.inputAmountListener(0)
      return amount
    }
  }

  async initializeAdmission() {
    const command = `#B#0#0#0#`
    const response = await this.sendCommandWithAutoInit(command)
    if (response.includes('#ER:')) {
      throw new Error(t('checkout.cashlogy.machine-not-connected'))
    }
    if (response.includes('WR:LEVEL')) {
      this.notifyLowMoney()
    }
  }

  async finalizeAdmission() {
    const command = `#J#`
    const response = await this.sendCommandWithAutoInit(command)
    if (response.includes('WR:LEVEL')) {
      this.notifyLowMoney()
    }
    const parts = this.parseResponse(response)
    return parts[2]
  }

  async consultCurrentAdmissionAmount(): Promise<number> {
    const command = `#Q#`
    const response = await this.sendCommand(command)
    const parts = this.parseResponse(response)
    if (parts.includes('#ER:')) {
      throw new Error(t('checkout.cashlogy.machine-not-connected'))
    }
    return parts[2]
  }

  async dispense(amount: number) {
    const { currency } = useMoney()
    const dialog = useDialog()
    const command = `#P#${amount}#0#0#0#`
    const response = await this.sendCommandWithAutoInit(command)
    if (response.includes('#ER:GENERIC#')) {
      const parts = this.parseResponse(response)
      const refundAmount = parts[2]
      const missingAmount = amount - refundAmount
      dialog({
        title: t('checkout.cashlogy.error-change'),
        content: t('checkout.cashlogy.error-change-description', {
          amount: currency(missingAmount)
        })
      })
    }
    return amount
  }

  async payIn(amount: number) {
    const command = `#A#2#`
    await this.sendCommandWithAutoInit(command)
    let inputAmount = 0
    while (inputAmount < amount && !this.cancelled) {
      inputAmount = await this.consultCurrentAdmissionAmount()
      this.inputAmountListener(inputAmount)
      await lastUtils.sleep(250)
    }
    this.inputAmountListener(0)
    return await this.finalizeAdmission()
  }

  async payOut(amount: number) {
    return await this.dispense(amount)
  }

  async close() {
    const command = `#E#`
    const response = await this.sendCommandWithAutoInit(command)
    this.socket?.close()
    const parts = response.split('#')
    return parseInt(parts[1])
  }

  async getCurrentAmount() {
    const command = `#T#0#`
    const response = await this.sendCommand(command)
    const parts = response.split('#')
    try {
      return parseInt(parts[2]) + parseInt(parts[3])
    } catch {
      return 0
    }
  }

  async refreshTotalAmount() {
    this.totalAmountListener(await this.getCurrentAmount())
  }

  async cancel() {
    this.cancelled = true
  }

  async openBackoffice() {
    const { notifyError, notifySuccess } = useNotifications()
    const command = '#G#1#1#1#1#1#1#1#1#1#1#1#1#1#1#1#'
    const response = await this.sendCommandWithAutoInit(command)
    if (response.includes('#ER:')) {
      notifyError({
        title: t('checkout.cashlogy.machine-not-connected'),
        icon: 'close'
      })
    } else {
      notifySuccess({
        title: t('cash-machine-status.backoffice-message')
      })
    }
  }

  private parseResponse(response: string) {
    const parts = response.split('#')
    return [parts[0], parts[1], parseInt(parts[2])] as const
  }

  private notifyLowMoney() {
    const { notifyError } = useNotifications()
    notifyError({
      title: t('checkout.cashlogy.low-money')
    })
  }
}

export default new Cashlogy()
