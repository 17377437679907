<template>
  <div class="h-auto w-full" @click="focusInput">
    <div
      :class="[
        ...errorClasses,
        ...focusClasses,
        ...disabledClasses,
        ...backgroundColors,
        ...textColors,
        ...sizeClasses,
        size === 'x-small' ? 'rounded-[0.25rem] !h-auto' : 'rounded-xl'
      ]"
      class="w-full flex items-center overflow-hidden box-border border border-transparent"
    >
      <slot name="left"></slot>
      <textarea
        v-if="type === 'textarea'"
        ref="input"
        v-model="modelValue"
        class="appearance-none focus:outline-none px-5 py-3 w-full leading-tight bg-transparent"
        :class="[...placeholderClasses, ...sizeClasses]"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
        :readonly="readOnly"
        @keyup.enter="$emit('enter')"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
      />
      <input
        v-else
        ref="input"
        v-model="modelValue"
        class="appearance-none focus:outline-none w-full leading-tight bg-transparent"
        :class="[
          ...placeholderClasses,
          size === 'x-small' ? 'px-2 h-6' : 'px-5 h-full'
        ]"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
        :readonly="readOnly"
        :type="type"
        @keyup.enter="$emit('enter')"
        @input="$emit('input', $event)"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
      />
      <div
        v-if="helperText"
        class="flex items-center justify-center h-9 text-sm capitalize text-nowrap text-primary flex-row pr-5 pointer-events-none"
      >
        {{ helperText }}
      </div>
      <slot name="right"></slot>
    </div>
    <div v-if="errorMessage && wrongValue" class="text-sm text-red">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineModel, defineProps, ref } from 'vue'

import {
  useBackgroundColorsClasses,
  useDisabledClasses,
  useErrorClasses,
  useFocusClasses,
  usePlaceholderClasses,
  useSizeClasses,
  useTextColorsClasses
} from '../classes'
import type { Size } from '../types'
import { type InputProps } from './inputTypes'

const props = withDefaults(defineProps<InputProps>(), {
  placeholder: '',
  icon: '',
  errorMessage: '',
  size: 'small',
  iconPosition: 'right',
  maxLength: 255,
  autocomplete: 'off',
  wrongValue: false,
  readOnly: false
})

defineEmits<{
  enter: []
  input: [Event]
  focus: [FocusEvent]
  blur: [FocusEvent]
}>()

const input = ref<HTMLInputElement>()

const errorClasses = computed(() => useErrorClasses(props.wrongValue))
const placeholderClasses = computed(() => usePlaceholderClasses(props.size))
const focusClasses = computed(() => useFocusClasses())
const disabledClasses = computed(() => useDisabledClasses(props.disabled))
const backgroundColors = computed(() => useBackgroundColorsClasses())
const textColors = computed(() => useTextColorsClasses())
const sizeClasses = computed(() => {
  return props.type === 'textarea'
    ? useTextAreaSizeClasses(props.size)
    : useSizeClasses(props.size)
})

const modelValue = defineModel<string | number | null>({ default: null })

function useTextAreaSizeClasses(size: Size) {
  switch (size) {
    case 'small':
      return ['text-xs', 'h-16']
    case 'medium':
      return ['text-sm', 'h-20']
    case 'large':
      return ['text-base', 'h-24']
    default:
      return []
  }
}

function focusInput() {
  input.value?.focus()
}

defineExpose({ focusInput, inputRef: input })
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
