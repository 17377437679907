import { App as CapacitorApp } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import { Network } from '@capacitor/network'
import { SplashScreen } from '@capacitor/splash-screen'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { register as registerSwiper } from 'swiper/element/bundle'
import { createApp } from 'vue'

import {
  ConsoleTracker,
  LDialogPlugin,
  LNotificationPlugin,
  PosthogTracker,
  trackerPlugin
} from '@last/core-ui/paprika'
import { vHorizontalScroll, vTapHint } from '@last/core-ui/paprika/directives'

import AppRoot from '@/AppRoot.vue'
import appStorage from '@/drivers/appStorage'
import i18n from '@/i18n'
import jobs from '@/jobs'
import localDb from '@/localDb'
import { ManagerConfirmationPlugin } from '@/plugins/managerConfirmation'
import { router } from '@/router'
import pinia from '@/store'
import { useAuthStore } from '@/store/auth'
import { useCatalogStore } from '@/store/catalog'
import { useConfigStore } from '@/store/config'
import { useCouriersStore } from '@/store/couriers'
import { usePromotionsStore } from '@/store/promotions'
import { useStatusStore } from '@/store/status'
import { useTabsStore } from '@/store/tabs'
import sync from '@/sync/service'
import { Catalogs } from '@/types'

import App from './app'
import images from './images'
import { rum } from './monitoring'
import { UpdateModalPlugin } from './plugins/updateVersion'

import './style.css'

const app = createApp({
  name: 'LastPOS',
  ...AppRoot
})

app.config.performance = import.meta.env.DEV

app.directive('taphint', vTapHint)
app.directive('horizontal-scroll', vHorizontalScroll)
app.use(pinia)
app.use(i18n)
app.use(router)
app.use(ManagerConfirmationPlugin)
app.use(UpdateModalPlugin)
app.use(LDialogPlugin)
app.use(LNotificationPlugin)

rum.setRouter(router)
if (import.meta.env.PROD) {
  app.config.errorHandler = err => {
    rum.addError(err)
  }
}

if (import.meta.env.VITE_POSTHOG_API_KEY) {
  app.use(trackerPlugin, {
    tracker: new PosthogTracker(import.meta.env.VITE_POSTHOG_API_KEY)
  })
} else {
  app.use(trackerPlugin, {
    tracker: new ConsoleTracker()
  })
}

registerSwiper()

app.mount('#app')

Network.addListener('networkStatusChange', status => {
  const statusStore = useStatusStore(pinia)
  if (status.connected) {
    statusStore.updateNetworkStatus(true)
  } else {
    statusStore.updateNetworkStatus(false)
  }
})

async function getOldStorage() {
  const oldStorage = (await appStorage.getItem('vuex')) || '{}'
  let storeData
  if (typeof oldStorage === 'string') {
    storeData = JSON.parse(oldStorage || '{}')
  } else {
    storeData = oldStorage
  }
  return { auth: storeData?.auth, config: storeData?.config }
}

async function removeappxData() {
  return appStorage.removeItem('vuex')
}

async function init() {
  App.notifyAppReady()
  const auth = useAuthStore(pinia)
  const config = useConfigStore(pinia)
  const oldStorage = await getOldStorage()
  const oldAuthData = oldStorage.auth
  if (oldAuthData) {
    auth.locationId = oldAuthData.locationId
    auth.accessToken = oldAuthData.accessToken
    config.device.id = oldStorage.config.device.id
    await removeappxData()
  }
  let tabsTables: any
  const tabs = useTabsStore(pinia)
  if (!sync.isInitialized()) {
    const tabKeys = Object.keys(tabs).map(key => `tabs:${key}`)
    tabsTables = await localDb.loadTables(tabKeys)
    tabsTables = Object.keys(tabsTables).reduce((res, key) => {
      res[key.replace('tabs:', '')] = tabsTables[key]
      return res
    }, {} as any)
  }
  if (!sync.isInitialized()) {
    tabs.replaceTabs(tabsTables)
  }
  const catalog = useCatalogStore(pinia)
  appStorage.getItem<Catalogs>('catalogs').then(async catalogs => {
    if (catalogs) {
      await catalog.refreshCatalogs(catalogs)
    }
    catalog.refreshCatalogs()
  })
  config.refreshConfig()
  const locationId = auth.locationId
  const status = useStatusStore(pinia)
  const promotions = usePromotionsStore(pinia)
  const couriers = useCouriersStore(pinia)
  await status.updateCurrentStatus()
  if (locationId) {
    auth.refreshCurrentLocation()
    status.refreshErrorMessages()
    auth.refreshBillingStatus()
    promotions.refreshPromotions()
  }
  images.removeOldCache()
  CapacitorApp.addListener('appStateChange', state => {
    if (state.isActive) {
      sync.resync()
      catalog.refreshCatalogs()
      if (config.config.organizationConfig.promotions) {
        promotions.refreshPromotions()
      }
      if (config.config.organizationConfig.deliveryFleet) {
        couriers.refreshCouriers()
      }
    }
  })
  jobs.start()
  SplashScreen.hide()
}

function initSafeArea() {
  if (!['android', 'ios'].includes(Capacitor.getPlatform())) return
  const updateProperties = () => {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(
          `--safe-area-inset-${key}`,
          `max(env(safe-area-inset-${key}), ${value}px)`
        )
      }
    })
  }
  updateProperties()
  SafeArea.addListener('safeAreaChanged', () => updateProperties())
}

function initKeyboard() {
  if (!['android', 'ios'].includes(Capacitor.getPlatform())) return
  Keyboard.addListener('keyboardWillShow', info => {
    document.body.style.setProperty(
      '--keyboard-height',
      `${info.keyboardHeight}px`
    )
  })

  Keyboard.addListener('keyboardWillHide', () => {
    document.body.style.setProperty('--keyboard-height', '0px')
  })
}

if (window.location.href.includes('refreshLocalStorage')) {
  localStorage.clear()
}

initSafeArea()
initKeyboard()
init()

export default app
