<template>
  <div class="flex flex-col items-center mt-6 gap-4 h-full sm:h-96">
    <l-pin-input
      v-model="pin"
      :length="4"
      :has-error="wrongValue"
      :show-eye="false"
    />
    <CalculatorKeypad
      class="w-72"
      :mode="CalculatorKeypadMode.SIMPLE"
      focused
      @number="onNumberClicked"
      @action="onActionClicked"
    />
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, ref, withDefaults } from 'vue'

import { LPinInput } from '@last/core-ui/paprika'

import CalculatorKeypad from '../Calculator/CalculatorKeypad.vue'
import {
  CalculatorKeypadActions,
  CalculatorKeypadActionsType,
  CalculatorKeypadMode
} from '../Calculator/CalculatorUtils'

withDefaults(
  defineProps<{
    wrongValue?: boolean
  }>(),
  {
    wrongValue: false
  }
)

const pin = ref<string>('')
const emit = defineEmits(['complete'])

function onNumberClicked(number: number): void {
  if (pin.value.length < 4) {
    pin.value += number
  }
  if (pin.value.length === 4) {
    emit('complete', pin.value)
  }
}

function onActionClicked(action: CalculatorKeypadActionsType): void {
  if (action === CalculatorKeypadActions.CLEAR) {
    pin.value = ''
    return
  }

  if (action === CalculatorKeypadActions.BACKSPACE) {
    pin.value = pin.value.slice(0, -1)
    return
  }
}
</script>
