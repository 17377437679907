<template>
  <div
    class="rounded-lg px-3 gap-1 flex items-center text-sm w-fit capitalize"
    :class="[
      properties.bg,
      properties.text,
      { 'h-6': props.size === 'small', 'h-9': props.size === 'medium' }
    ]"
  >
    <l-icon :name="properties.icon" />
    <slot>
      {{ properties.content }}
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { LIcon, type LIconName } from '@last/core-ui/paprika'

const { t } = useI18n()
type Props = {
  status: 'active' | 'pending' | 'error' | 'inactive' | 'loading'
  size?: 'small' | 'medium'
}
const props = withDefaults(defineProps<Props>(), {
  status: 'active',
  size: 'medium'
})
const properties = computed(() => {
  switch (props.status) {
    case 'active':
      return {
        bg: 'bg-g-100',
        text: 'text-g-500',
        content: t('status.active'),
        icon: 'check-circle' as LIconName
      }
    case 'pending':
      return {
        bg: 'bg-y-100',
        text: 'text-y-500',
        content: t('status.pending'),
        icon: 'alert-circle' as LIconName
      }
    case 'error':
      return {
        bg: 'bg-r-100',
        text: 'text-r-500',
        content: t('status.error'),
        icon: 'close-circle' as LIconName
      }
    case 'inactive':
      return {
        bg: 'bg-b-100',
        text: 'text-n-800 opacity-30',
        content: t('status.inactive'),
        icon: 'inactive' as LIconName
      }
    case 'loading':
      return {
        bg: 'bg-transparent',
        text: 'text-n-400',
        content: t('status.loading'),
        icon: 'refresh' as LIconName
      }
    default:
      return {
        bg: 'bg-transparent',
        text: 'text-n-400',
        content: t('status.loading'),
        icon: 'refresh' as LIconName
      }
  }
})
</script>
