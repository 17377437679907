<template>
  <div class="flex flex-col h-full">
    <l-option-selector
      v-if="tabs.length > 1"
      v-model="selectedTab"
      :options="tabs"
      class="mb-4 flex-shrink-0"
    />

    <div v-if="selectedTab == 'total'" class="flex-1 flex flex-col">
      <calculator
        class="h-full"
        :initial-to-pay="total"
        keypad-mode="normal"
        display-mode="toPay"
        @change="onChange"
      />
      <l-button
        v-if="showOpenTill && !useCashMachine"
        type="text"
        size="medium"
        class="mt-4"
        @click="openCashDrawer"
      >
        {{ $t('start-shift.open-till') }}
      </l-button>
    </div>

    <div
      v-if="selectedTab === 'billsTotal'"
      class="flex flex-col flex-1 overflow-hidden"
    >
      <calculator-display
        class="mb-4"
        mode="toPay"
        pending="0"
        change="0"
        :to-pay="currency(billsTotal)"
      />
      <div class="flex-1 overflow-y-scroll scrolling-touch min-h-0">
        <div class="flex flex-col gap-3">
          <div
            v-for="(bill, index) in bills"
            :key="bill"
            class="flex justify-between"
          >
            <div class="font-heading text-n-0 font-medium w-36">
              {{ currency(bill) }}
            </div>
            <l-input
              v-model.integer="billsAmount[index]"
              size="small"
              type="number"
              :min="0"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end flex-col gap-2 flex-shrink-0 mt-8">
      <l-button class="w-full" @click="done()">
        {{ doneLabel }}
      </l-button>
      <l-button
        v-if="lastShiftEndAmount && !useCashMachine"
        type="secondary"
        @click="total = lastShiftEndAmount"
      >
        {{ $t('cash-amount.copy-last') }}:
        {{ currency(lastShiftEndAmount) }}
      </l-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  LInput,
  LOptionSelector,
  LButton,
  useDialog
} from '@last/core-ui/paprika'
import CashMachine from '@/integrations/cashmachine/cashmachine'
import { computed } from 'vue'
import { onMounted } from 'vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Calculator from '../Calculator/Calculator.vue'
import { CalculatorPayload } from '../Calculator/CalculatorUtils'
import { defineProps, withDefaults } from 'vue'
import Printer from '@/integrations/printer/printer'
import CalculatorDisplay from '@/components/Calculator/CalculatorDisplay.vue'
import { useMoney } from '@/composables/useMoney'

const props = withDefaults(
  defineProps<{
    doneLabel: string
    initTotal: number | null
    lastShiftEndAmount?: number | null
    showBillsCounter?: boolean
    useCashMachine?: boolean
    showOpenTill?: boolean
  }>(),
  {
    doneLabel: 'Done',
    lastShiftEndAmount: null,
    initTotal: null,
    showBillsCounter: true,
    useCashMachine: false,
    showOpenTill: false
  }
)
const emit = defineEmits<{
  done: [value: number]
}>()

const { t } = useI18n()
const { currency } = useMoney()
const dialog = useDialog()

const tabs = ref<{ label: string; value: string }[]>([])
const selectedTab = ref('total')

const total = ref(0)
const bills = [
  1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000
]
const billsAmount = ref(Array(15))

const billsTotal = computed(() => {
  let total = 0
  bills.forEach((value, index) => {
    total += value * (billsAmount.value[index] ?? 0)
  })
  return total
})

const machineAmount = computed(() => {
  return CashMachine.totalAmount.value
})

onMounted(async () => {
  tabs.value = [
    {
      label: t('cash-amount.total'),
      value: 'total'
    }
  ]
  if (props.showBillsCounter && !props.useCashMachine) {
    tabs.value.push({
      label: t('cash-amount.bills-total'),
      value: 'billsTotal'
    })
  }
  if (props.useCashMachine) {
    let isCanceled = false
    const dialogInstance = dialog({
      icon: 'loading-spinner',
      iconAnimation: 'animate-spin',
      title: `${t('cash-amount.loading')} ${CashMachine.method}`,
      mainLabel: t('cash-amount.cancel'),
      onConfirm: () => {
        isCanceled = true
      }
    })
    await CashMachine.refreshTotalAmount()
    if (!isCanceled) {
      total.value = machineAmount.value
    }
    dialogInstance?.close()
  }
  if (props.initTotal) {
    total.value = props.initTotal
  }
})

function openCashDrawer() {
  Printer.openCashDrawer()
}

function onChange(event: CalculatorPayload) {
  total.value = event.toPay
}

function done() {
  if (selectedTab.value === 'total') {
    emit('done', total.value)
  } else {
    emit('done', billsTotal.value)
  }
}
</script>
