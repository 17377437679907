import { hasInjectionContext, inject, type Plugin } from 'vue'

import type { LIconName } from '../../components/icon.type'

let openDialog: (props: Props) => {
  close: () => void
}

type Props = {
  icon?: LIconName
  iconColor?: string
  iconAnimation?: 'animate-spin'
  image?: string
  avatar?: string
  title: string
  content?: string
  html?: string
  disabled?: boolean
  subContent?: string
  mainLabel?: string
  secondaryLabel?: string
  onConfirm?: () => void | Promise<void>
  onSecondary?: () => void | Promise<void>
  onCancel?: () => void
  onClose?: () => void
  noLabels?: boolean
  hiddenClose?: boolean
  /**
   * If true, when the user clicks outside the dialog, it will close the dialog.
   * @default true
   */
  dismissable?: boolean
}

export function init(openFunction: typeof openDialog) {
  openDialog = openFunction
}

export function dialog(props: Props) {
  if (!openDialog) {
    // eslint-disable-next-line no-console
    console.warn('Dialog wrapper not added in the app')
    return
  }
  if (props.secondaryLabel && !props.onSecondary) {
    const empty = () => {}
    props.onSecondary = props.onCancel || empty
  }
  return openDialog({
    dismissable: true,
    ...props
  })
}

export type Dialog = typeof dialog

export const LDialogPlugin: Plugin = {
  install(app) {
    app.config.globalProperties.$ldialog = dialog
    app.provide('$ldialog', dialog)
  }
}

export function useDialog() {
  const dialogInstance = hasInjectionContext()
    ? inject<Dialog>('$ldialog')
    : dialog

  if (!dialogInstance) {
    throw new Error('LDialogPLugin is not installed')
  }

  return dialogInstance
}
