<template>
  <div class="flex items-center font-body text-sm">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      ref="tabRefs"
      class="mr-2 h-9 px-6 flex flex-row items-center border rounded-full transition-colors dark:text-n-0 text-n-800 cursor-pointer shrink-0"
      :class="{
        'border-transparent': tab.id !== model,
        '!text-n-400 pointer-events-none': tab.disabled,
        'bg-v-300 border-transparent !text-n-0':
          tab.id === model && type === 'primary',
        'dark:bg-n-600': tab.id !== model && type === 'primary',
        'border-n-800 dark:border-n-0': tab.id === model && type === 'secondary'
      }"
      @click="clickTab(tab)"
    >
      <l-icon v-if="tab.icon" :name="tab.icon" size="small" class="mr-2" />
      <span class="leading-none">{{ tab.name }}</span>
      <l-badge
        v-if="tab.badge !== undefined"
        class="ml-2"
        type="large"
        :value="tab.badge"
      ></l-badge>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineModel, onMounted, ref, watch } from 'vue'

import type { LIconName } from './icon.type'
import LIcon from './Icon.vue'
import LBadge from './LBadge.vue'

export type Tab = {
  id: string
  name: string
  disabled?: boolean
  badge?: number
  icon?: LIconName
}

type Props = {
  type?: 'primary' | 'secondary'
  tabs: Tab[]
}

const props = withDefaults(defineProps<Props>(), {
  type: 'primary'
})

const model = defineModel<string>()

const tabRefs = ref<HTMLElement[]>([])

onMounted(() => {
  if (!model.value) {
    model.value = props.tabs[0].id
  }
})

watch(model, () => {
  if (!model.value) return
  const tabIndex = props.tabs.findIndex(t => t.id === model.value)
  tabRefs.value[tabIndex].scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'center'
  })
})

function clickTab(tab: Tab) {
  if (tab.disabled) return

  model.value = tab.id
}
</script>
