<template>
  <div class="rounded-2xl bg-n-700 flex flex-col h-full">
    <div class="pt-4 px-4">
      <div class="flex justify-between">
        <tab-title :tab-id="tabId" class="mr-3" size="big" />
        <div v-if="isCancelled || hasAlert">
          <l-status v-if="isCancelled" status="error">
            {{ $t('tabs.cancelled') }}
          </l-status>
          <l-status
            v-if="hasAlert"
            status="pending"
            class="cursor-pointer"
            @click="showAlert"
          >
            {{ $t('tabs.alert') }}
          </l-status>
        </div>
        <delivery-status
          v-else-if="tab.deliveryOrder && tab.open"
          :tab-id="tab.id"
        />
      </div>
    </div>
    <div class="p-2 flex-1 overflow-y-scroll">
      <div
        class="font-bold font-text text-n-10 p-4 flex justify-between text-2xl"
      >
        {{ $t('tabs.order-details') }}
        <l-icon
          v-if="isOpen"
          name="edit"
          size="medium"
          class="opacity-50 text-v-300 cursor-pointer hover:text-v-200 transition-colors"
          @click="$emit('edit')"
        />
      </div>
      <div class="pl-4">
        <product-component
          v-for="product in products"
          :key="product.id"
          :product="product"
          allow-remove
          allow-discount
        />
      </div>
    </div>
    <div class="p-6 text-n-10">
      <balance :tab-id="tab.id" />
      <tab-actions :tab-id="tab.id" class="pt-4" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'

import { LIcon, LStatus, useDialog } from '@last/core-ui/paprika'

import Balance from '@/components/core/Balance.vue'
import ProductComponent from '@/components/core/Product.vue'
import TabActions from '@/components/tabs/TabActions.vue'
import { useTabs } from '@/composables/useTabs'
import type { DeliveryOrder } from '@/types'

import DeliveryStatus from './DeliveryStatus.vue'
import TabTitle from './TabTitle.vue'

const { t } = useI18n()
const dialog = useDialog()

defineEmits(['edit'])

type Props = {
  tabId: string
}

type DeliveryOrderWithError = DeliveryOrder & { errorMessage: string }

const props = defineProps<Props>()

const tabId = computed(() => props.tabId)
const { tab, allProducts, hasAlert, isCancelled } = useTabs(tabId)

const products = computed(() => {
  if (!tabId.value) return []
  return allProducts.value
})

const isOpen = computed(() => {
  return tab.value?.open
})

function showAlert() {
  const message =
    t('tabs.shipment-error-message') +
      ':\n' +
      (tab.value.deliveryOrder as DeliveryOrderWithError)?.errorMessage || ''
  dialog({
    title: t('tabs.shipment-error-title'),
    content: message
  })
}
</script>
