import { Capacitor } from '@capacitor/core'
import { setDefaultOptions } from 'date-fns'
import { ca, de, enGB, es } from 'date-fns/locale'
import moment, { Moment } from 'moment-timezone'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { lastUtils } from '@last/core'
import { LIconName } from '@last/core-ui/paprika'

import { getConfig, sendUpdateHardwarePreferences } from '@/api/config'
import { addDevice } from '@/api/device'
import app from '@/app'
import i18n from '@/i18n'
import images from '@/images'
import CashMachine from '@/integrations/cashmachine/cashmachine'
import Dataphone from '@/integrations/dataphone/dataphone'
import Printer from '@/integrations/printer/printer'
import { useAuthStore } from '@/store/auth'
import { useTillStore } from '@/store/till'
import sync from '@/sync/service'
import { Config } from '@/types/config'
import { DeviceConfig } from '@/types/device'

const { t } = i18n.global

export const useConfigStore = defineStore(
  'config',
  () => {
    const printers = ref<Record<string, any>>({})
    const config = ref<Config>({
      employees: {
        selectionType: 'names'
      },
      virtualBrands: [],
      tills: {
        cash: []
      },
      featureToggles: {},
      locationCountryCode: 'ES',
      currencyCode: 'EUR',
      paymentMethods: [],
      paymentMethodsConfig: [],
      blindTill: false,
      billCopies: 1,
      locationName: '',
      enableKitchenOrders: true,
      printers: {},
      autoprintReceipts: false,
      autoprintKitchenOrders: false,
      autoprintBills: false,
      dataphoneConfigs: [],
      adyenDataphones: [],
      preparationMinutes: 0,
      showTakeAwayTabPopup: false,
      onlyMasterPrinting: false
    })
    const mute = ref(localStorage.getItem('mute') || false)
    const device = ref<DeviceConfig>({
      mode: 'master',
      platform: 'web'
    })
    const demoMode = ref(false)
    const updateTime = ref<Moment | null>(null)

    const virtualBrands = computed(() => config.value.virtualBrands)

    const reservations = computed(() => config.value.reservations)

    const tillNameByTillId = computed(() => {
      const values = Object.values(config.value.tills).flat()
      return (tillId: string) => values.find(till => till.id === tillId)!.name
    })

    const tills = computed(() => {
      return config.value.tills
    })

    const currencyIcon = computed(() => {
      return lastUtils.getCurrencyIcon(config.value?.currencyCode) as LIconName
    })

    const appVersion = computed(() => {
      return device.value.appVersion || 'web'
    })

    const getVirtualBrand = computed(() => (virtualBrandId: string) => {
      return config.value.virtualBrands?.find(b => b.id === virtualBrandId)
    })

    const shipmentProviders = computed(() => config.value.shipmentProviders)

    const currentAdyenDataphone = computed(() => {
      const poiid = app.serial
      return config.value.adyenDataphones?.find(
        dataphone => dataphone.poiid === poiid
      )
    })

    async function refreshConfig(inputConfig?: Config) {
      const auth = useAuthStore()
      if (!auth.isAuthenticated) return
      let newConfig = inputConfig
      if (!newConfig) {
        try {
          newConfig = await getConfig()
        } catch (_) {
          newConfig = {
            ...config.value,
            printers: Object.values(config.value.printers)
          }
        }
      }

      let channel
      if (
        newConfig?.featureToggles &&
        Object.keys(newConfig.featureToggles).includes('release_channel_beta')
      ) {
        channel = 'beta'
      }
      app.checkForUpdates(channel)

      newConfig.virtualBrands.forEach(brand => {
        images.preloadLogo(brand.imageId)
      })
      let language = newConfig.language || 'es'
      if (demoMode.value) {
        language = navigator.language.split('-')[0] as 'es' | 'ca' | 'de' | 'en'
      }
      i18n.global.locale.value = language
      if (newConfig.workingTime?.timezone) {
        moment.tz.setDefault(newConfig.workingTime?.timezone || 'Europe/Madrid')
      }
      moment.locale(language)
      moment.updateLocale(language, {
        relativeTime: {
          future: t('relative-time.future', { time: '%s' }),
          past: '%s',
          s: t('relative-time.one-minute'),
          ss: t('relative-time.one-minute'),
          m: t('relative-time.one-minute'),
          mm: t('relative-time.minutes', { time: '%d' }),
          h: t('relative-time.one-hour'),
          hh: t('relative-time.hours'),
          d: t('relative-time.hours'),
          dd: t('relative-time.hours'),
          w: t('relative-time.hours'),
          ww: t('relative-time.hours'),
          M: t('relative-time.hours'),
          MM: t('relative-time.hours'),
          y: t('relative-time.hours'),
          yy: t('relative-time.hours')
        }
      })
      const locales = {
        es: es,
        ca: ca,
        de: de,
        en: enGB
      }
      setDefaultOptions({
        locale: locales[language]
      })
      Printer.loadPrinters(newConfig.printers)
      const printers = newConfig.printers.reduce((res, printer) => {
        res[printer.id] = printer
        return res
      }, {})
      config.value = {
        ...newConfig,
        printers
      }
      refreshDeviceInfo()
      refreshUpdateTime()
    }

    async function refreshDeviceInfo() {
      let addedDevice: DeviceConfig | Partial<DeviceConfig> = {}
      const platform = Capacitor.getPlatform()
      if (platform !== 'web') {
        const deviceInfo = await app.getDeviceInfo()
        // HACK: This is to keep the same behavior as the old code. If we have a
        // device stored we reuse the same id.
        // This should not be necessary because that seems to be already the behavior
        // of Capacitor. In Electron it keeps the generated id in the localStorage.
        if (device.value.id) {
          deviceInfo.id = device.value.id
        }
        addedDevice = await addDevice(deviceInfo)
        const auth = useAuthStore()
        auth.setAccessToken(addedDevice.accessToken)
        device.value = addedDevice as DeviceConfig
      }
      const enabledTills = tills.value.cash
      const preferredTill = enabledTills?.find(
        till => till.id === addedDevice.preferredTill
      )
      const till = useTillStore()
      if (preferredTill) {
        till.setTill(preferredTill)
      } else if (enabledTills.length === 1) {
        till.setTill(enabledTills[0])
      } else if (enabledTills.length > 1) {
        till.updateSelectedTillIfNull(enabledTills[0])
      }
      CashMachine.init(config.value, addedDevice.preferredCashMachine)
      Dataphone.init(config.value, addedDevice.preferredDataphone)
    }

    function toggleMute() {
      mute.value = !mute.value
      localStorage.setItem('mute', mute.value)
    }

    function updateShipmentProvider(shipmentProvider: {
      provider: string | null
      ownFleetEnabled: boolean
    }) {
      sync.record('updateShipmentProvider', shipmentProvider)
    }

    function updateManualShipment(manualShipment: boolean) {
      sync.record('updateManualShipment', manualShipment)
    }

    function activateDemoMode() {
      demoMode.value = true
    }

    async function updateHardwarePreferences(hardwarePreferences: any) {
      if ('preferredCashMachine' in hardwarePreferences)
        CashMachine.init(config.value, hardwarePreferences.preferredCashMachine)

      if ('preferredDataphone' in hardwarePreferences)
        Dataphone.init(config.value, hardwarePreferences.preferredDataphone)

      device.value = {
        ...device.value,
        ...hardwarePreferences
      }

      if (device.value.id) {
        await sendUpdateHardwarePreferences(
          device.value.id,
          hardwarePreferences
        )
      }
    }

    function refreshUpdateTime() {
      updateTime.value = moment()
    }

    const isShipmentProvidersEnabled = computed<boolean>(() => {
      if (!shipmentProviders.value || !shipmentProviders.value.providers)
        return false

      return shipmentProviders.value.providers.some(provider => {
        return provider.config?.enabled
      })
    })

    return {
      printers,
      config,
      mute,
      device,
      demoMode,
      updateTime,
      virtualBrands,
      reservations,
      tillNameByTillId,
      tills,
      currencyIcon,
      appVersion,
      getVirtualBrand,
      shipmentProviders,
      currentAdyenDataphone,
      refreshConfig,
      refreshDeviceInfo,
      toggleMute,
      updateShipmentProvider,
      updateManualShipment,
      activateDemoMode,
      updateHardwarePreferences,
      refreshUpdateTime,
      isShipmentProvidersEnabled
    }
  },
  {
    persist: true
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot))
}
