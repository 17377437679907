<template>
  <div
    v-if="svg"
    class="flex items-center justify-center flex-shrink-0"
    :class="{
      'current-color-fill': !originalColor,
      'w-3 h-3': props.size === 'x-small',
      'w-4 h-4': props.size === 'small',
      'w-6 h-6': props.size === 'medium',
      'w-8 h-8': props.size === 'large'
    }"
    v-html="svg"
  />
</template>

<script lang="ts">
const oldModules = import.meta.glob('../../components/assets/svg/**/*.svg', {
  query: '?raw',
  import: 'default',
  eager: true
})

const modules = import.meta.glob('../assets/icons/**/*.svg', {
  query: '?raw',
  import: 'default',
  eager: true
})
</script>

<script lang="ts" setup>
import { computed } from 'vue'

import type { Size } from '@last/core-ui/paprika/components/types'

import type { LIconName } from './icon.type'

interface Props {
  name: LIconName
  size?: Size
  originalColor?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  originalColor: false,
  size: 'small'
})

const svg = computed<string>(() => {
  const icon = modules['../assets/icons/' + props.name + '.svg']
  const oldIcon =
    oldModules['../../components/assets/svg/' + props.name + '.svg']
  if (!icon) {
    // eslint-disable-next-line no-console
    console.warn(`Deprecated icon name "${props.name}", find the new one.`)
  }
  if (icon && oldIcon) {
    // eslint-disable-next-line no-console
    console.warn(
      `Icon "${props.name}" found in both old and new icons, check if it's the same.\n Using the old one for now, migrate to the l-icon component.`
    )
  }

  if (!icon && !oldIcon) {
    // eslint-disable-next-line no-console
    console.error(`Icon "${props.name}" not found.`)
    return ''
  }

  return (oldIcon ?? icon) as string
})
</script>

<style>
.current-color-fill path {
  fill: currentColor;
}
</style>
