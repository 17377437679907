<template>
  <l-modal
    :title="$t('tabs.select-courier')"
    size="small"
    class="py-20"
    @close="$emit('close')"
  >
    <div
      v-for="(courier, index) in couriers"
      :key="courier.id"
      class="border px-3 py-4 border-n-600 rounded-lg my-3 text-n-0 bg-n-700 cursor-pointer"
      @click="assignCourier(index)"
    >
      <div class="flex flex-row justify-between mx-5">
        <div>{{ courier.name }}</div>
        <div v-if="courierHasOrdersOnDelivery(courier)" class="text-r-400">
          {{ $t('tabs.on-delivery') }}
        </div>
        <div v-else class="text-g-500">
          {{ $t('tabs.free') }}
        </div>
      </div>
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

import { LModal } from '@last/core-ui/paprika'

import { useCouriersStore } from '@/store/couriers'
import { useTabsStore } from '@/store/tabs'
import { Courier } from '@/types'

const couriersStore = useCouriersStore()
const tabsStore = useTabsStore()

const { couriers } = storeToRefs(couriersStore)
const { tabs } = storeToRefs(tabsStore)

const emit = defineEmits<{
  close: []
  assignCourier: [Courier]
}>()

const openTabs = computed(() => {
  return Object.values(tabs.value).filter(tab => tab.open)
})

function courierHasOrdersOnDelivery(courier: Courier) {
  return openTabs.value.some(
    tab =>
      tab.deliveryOrder &&
      tab.deliveryOrder.status === 'ON_DELIVERY' &&
      tab.deliveryOrder.courierId === courier.id
  )
}
function assignCourier(key: string) {
  emit('assignCourier', couriers.value[key])
}
</script>
