import { acceptHMRUpdate, defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'
import { computed, ref } from 'vue'

import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import PromotionApplier from '@last/core/src/PromotionApplier'

import { getPromotions as fetchPromotions } from '@/api/promotions'
import { useNotifications } from '@/composables/useNotifications'
import { useTabs } from '@/composables/useTabs'
import i18n from '@/i18n'
import { useConfirm } from '@/plugins/managerConfirmation'
import sync from '@/sync/service'
import { Promotion, TabPromotion } from '@/types'

import { useCatalogStore } from './catalog'
import { useConfigStore } from './config'
import { useTabsStore } from './tabs'

export const usePromotionsStore = defineStore('promotions', () => {
  const promotions = ref<Promotion[]>([])
  const tabPromotions = ref<Record<string, TabPromotion[]>>({})
  const customerPromotions = ref<Record<string, Promotion[]>>({})

  const confirm = useConfirm()

  const getTabGlobalPromotion = computed(() => (tabId: string) => {
    return tabPromotions.value[tabId]?.find(promotion => promotion.global)
  })

  const getPromotions = computed(() => {
    const config = useConfigStore()
    if (!config.config.organizationConfig.promotions) return []
    return promotions.value.filter(
      promotion =>
        promotion.enabled &&
        (!promotion.maxRedemptions || promotion.remainingRedemptions > 0)
    )
  })

  const getCustomerPromotions = computed(() => (customerId: string) => {
    return (customerPromotions.value[customerId] || []).filter(
      promotion =>
        promotion.enabled &&
        (!promotion.maxRedemptions || promotion.remainingRedemptions > 0)
    )
  })

  const getPromotionsWithPoints = computed(() => {
    return getPromotions.value.filter(promotion => promotion.pointsExpense)
  })

  const getPromotionsWithoutPoints = computed(() => {
    return getPromotions.value.filter(promotion => !promotion.pointsExpense)
  })

  const usedPointsInTab = computed(() => (tabId: string) => {
    const { allProducts, allBills: getBills } = useTabs(tabId)
    const products = allProducts.value
    const bills = getBills.value
    const tabPromotion = tabPromotions.value[tabId]
    const productPoints = products.reduce(
      (sum, product) => sum + product.pointsExpense * product.quantity,
      0
    )
    const billPoints = bills.reduce(
      (sum, bill) =>
        sum + ((bill.discount && bill.discount.pointsExpense) || 0),
      0
    )
    const promotionPoints = (tabPromotion || []).reduce(
      (sum, promotion) => sum + (promotion.pointsExpense || 0),
      0
    )

    return productPoints + billPoints + promotionPoints
  })

  async function refreshPromotions(inputPromotions?: Promotion[]) {
    let newPromotions = inputPromotions
    if (!newPromotions) {
      newPromotions = await fetchPromotions()
    }
    promotions.value = newPromotions ?? []
  }

  async function refreshTabPromotions(
    newTabPromotions: Record<string, TabPromotion[]>
  ) {
    tabPromotions.value = newTabPromotions
  }

  async function updateCustomerPromotions({
    customerId,
    customerPromotions: newCustomerPromotions
  }: {
    customerId: string
    customerPromotions: Promotion[]
  }) {
    customerPromotions.value[customerId] = newCustomerPromotions
  }

  async function deleteTabPromotion({
    tabPromotion,
    tabId
  }: {
    tabPromotion: TabPromotion
    tabId: string
  }) {
    sync.record('promotionDeletedFromTab', {
      tabPromotionId: tabPromotion.id,
      tabId
    })
    recalculatePromotionsInProducts(tabId)
  }

  async function updateTabPromotion({
    promotion,
    tabId
  }: {
    promotion: TabPromotion
    tabId: string
  }) {
    const isDiscountManager = await confirm('DISCOUNT_MANAGER')
    if (!isDiscountManager) {
      const { notifyError } = useNotifications()
      notifyError({
        title: i18n.global.t('employees.not-allowed')
      })
      return Promise.reject()
    }
    const tabPromotion = (tabPromotions.value[tabId] || []).find(
      tabPromo => tabPromo.promotionId === promotion.id
    )
    if (tabPromotion) {
      sync.record('promotionDeletedFromTab', {
        tabPromotionId: tabPromotion.id,
        tabId
      })
    } else {
      const newTabPromotion = {
        id: uuid(),
        tabId,
        promotionId: promotion.id,
        name: promotion.name,
        description: promotion.description,
        pointsExpense: promotion.pointsExpense,
        discountType: promotion.discountType,
        discountAmount: promotion.discountAmount,
        allowRepeat: promotion.allowRepeat,
        global: promotion.global,
        freeDelivery: promotion.freeDelivery
      }
      sync.record('promotionAddedToTab', { tabPromotion: newTabPromotion })
    }
    recalculatePromotionsInProducts(tabId)
  }

  function recalculatePromotionsInProducts(tabId: string) {
    const { customerId, allProducts } = useTabs(tabId)
    const allPromotions = [
      ...promotions.value,
      ...(customerPromotions.value[customerId.value!] || [])
    ].reduce(
      (acc, promotion) => {
        acc[promotion.id] = promotion
        return acc
      },
      {} as Record<string, Promotion>
    )

    const availablePromotions = tabPromotions.value[tabId]
      ? tabPromotions.value[tabId].map(tabPromotion => ({
          ...tabPromotion,
          products: allPromotions[tabPromotion.promotionId]
            ? allPromotions[tabPromotion.promotionId].products
            : [],
          categories: allPromotions[tabPromotion.promotionId]
            ? allPromotions[tabPromotion.promotionId].categories
            : []
        }))
      : []
    const catalog = useCatalogStore()
    const productsToBeUpdated = new PromotionApplier(
      availablePromotions,
      catalog.products
    ).updateDiscountInProducts(
      allProducts.value.filter(product => product.notBilledQuantity > 0)
    )

    productsToBeUpdated.forEach((product: any) => {
      const productPromotion = promotions.value.find(
        promotion => promotion.id === product.promotionId
      )
      const productPromotionName = productPromotion
        ? productPromotion.name
        : null
      useTabsStore().updateProductDiscount({
        productId: product.id,
        discount: {
          discountType: product.discountType,
          discountAmount: product.discountAmount,
          discountConcept: product.discountConcept || productPromotionName,
          promotionId: product.promotionId
        },
        productPricing: ProductPriceCalculator.calculateProductPricing(product)
      })
    })
  }

  return {
    promotions,
    tabPromotions,
    customerPromotions,
    getTabGlobalPromotion,
    getPromotions,
    getCustomerPromotions,
    getPromotionsWithPoints,
    getPromotionsWithoutPoints,
    usedPointsInTab,
    refreshPromotions,
    refreshTabPromotions,
    updateCustomerPromotions,
    deleteTabPromotion,
    updateTabPromotion,
    recalculatePromotionsInProducts
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePromotionsStore, import.meta.hot))
}
