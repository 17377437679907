<template>
  <div
    :class="{ 'bg-n-600': sectionSelected }"
    class="border-b border-n-700 only:border-none"
  >
    <div
      v-if="showSectionName"
      class="flex items-center justify-between p-4 text-sm text-n-200 font-body cursor-pointer"
      @click="$emit('selectedSection')"
    >
      <div class="flex items-center">
        <l-radio
          :model-value="sectionSelected"
          class="mr-4 hidden sm:flex"
          @update:model-value="$emit('selectedSection')"
        />
        {{ sectionName }}
      </div>
      <l-icon
        v-if="showRemove"
        name="delete"
        class="justify-end text-n-0 cursor-pointer"
        @click.stop="$emit('onRemove')"
      />
    </div>
    <VueDraggable
      :model-value="elements"
      :empty-insert-threshold="40"
      direction="vertical"
      group="products"
      :force-fallback="true"
      :delay="250"
      :delay-on-touch-only="false"
      :touch-start-threshold="5"
      :animation="200"
      :component-data="{ name: 'list' }"
      @start="$emit('draggableStart')"
      @end="$emit('draggableEnd')"
      @update="event => $emit('onUpdate', event)"
      @add="event => $emit('onAdd', event)"
      @choose="sendVibration"
    >
      <div v-for="element in elements" :id="element.id" :key="element.id">
        <slot v-bind="{ id: element.id, item: element }"></slot>
      </div>
    </VueDraggable>
    <div ref="endSection"></div>
  </div>
</template>

<script setup lang="ts">
import { Haptics } from '@capacitor/haptics'
import { computed, ref, watch } from 'vue'
import { VueDraggable } from 'vue-draggable-plus'

import { LIcon, LRadio } from '@last/core-ui/paprika'

type Props = {
  showRemove?: boolean
  showSelectRadio?: boolean
  sectionName: string
  sectionSelected?: boolean
  elements: []
  showSectionName?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showRemove: false,
  showSelectRadio: true,
  sectionSelected: false,
  elements: () => [],
  showSectionName: true
})

defineEmits([
  'selectedSection',
  'onRemove',
  'onUpdate',
  'onAdd',
  'draggableStart',
  'draggableEnd'
])

const endSection = ref<HTMLDivElement>()

const elementsLength = computed(() => {
  return props.elements.length
})

watch(elementsLength, () => {
  const scrollTarget = endSection.value
  if (scrollTarget) {
    scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
})

function sendVibration() {
  try {
    Haptics.vibrate()
  } catch {
    //no vibration
  }
}
</script>
