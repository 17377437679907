import api from '@/api/fetch'
import { Config } from '@/types/config'

export function getConfig() {
  return api.get<Config>('/config').then(res => res.data)
}

export function sendUpdateHardwarePreferences(deviceId: string, config: any) {
  return api.put(`/devices/hardware-preferences/${deviceId}`, config)
}

export function getTime() {
  return api.get<{ time: string }>('/time').then(res => res.data)
}

export function getErrorMessages() {
  return api.get<any[]>('/error-messages').then(res => res.data)
}
