<template>
  <div class="flex flex-col w-full h-full bg-n-800 overflow-scroll pt-4 px-4">
    <categories-component
      :catalog-id="catalogId"
      :show-promotions="arePromotionsAvailable"
      :search-query="searchQuery"
      @category-selected="selectCategory"
      @update:search="searchQuery = $event"
      @search-bar-expanded="searchBarExpanded = $event"
    />
    <div
      v-if="selectedCategoryId === 'promotions' && !searchBarExpanded"
      class="h-full pt-5"
    >
      <promotion-list
        v-if="availablePromotionsWithNoPoints.length > 0"
        class="pb-8"
        :title="$t('ordering.available-promotions')"
        :promotions="availablePromotionsWithNoPoints"
        @promotion-selected="updateTabPromotionIntoTab"
      />
      <promotion-list
        v-if="availablePromotionsWithPoints.length > 0"
        class="pb-8"
        :promotions="availablePromotionsWithPoints"
        :title="$t('ordering.available-promotions-with-points')"
        :subtitle="$t('ordering.available-points', { points: customerPoints })"
        @promotion-selected="updateTabPromotionIntoTab"
      />
      <promotion-list
        v-if="availablePromotionProducts.length > 0"
        class="pb-8"
        :promotions="availablePromotionProducts"
        :title="$t('ordering.promotions-with-points')"
        :subtitle="$t('ordering.available-points', { points: customerPoints })"
        @promotion-selected="$emit('selectedProduct', $event)"
      />
    </div>
    <div
      v-else-if="selectedCategoryId && categories[selectedCategoryId]"
      class="h-full"
    >
      <div
        v-if="!searchBarExpanded"
        class="flex items-center justify-between mt-4"
      >
        <div class="font-heading text-xl font-bold text-n-0">
          {{ categories[selectedCategoryId].name }}
        </div>
        <div
          v-if="!['promotions'].includes(selectedCategoryId)"
          class="hidden sm:block"
        >
          <l-button
            color="primary"
            size="small"
            icon="plus"
            @click="newOpenProduct"
          />
        </div>
      </div>

      <product-list
        class="mt-4"
        :products="productsList"
        @selected-product="
          (product, quantity) => $emit('selectedProduct', product, quantity)
        "
      />
      <div
        v-if="productsList.length === 0"
        class="h-full flex items-center justify-center"
      >
        <EmptyCase
          type="empty"
          :enforced-translation-key="$t('search.no-results')"
          :button-text="$t('ordering.add-product')"
          @action="newOpenProduct"
        />
      </div>
    </div>
    <open-product
      v-if="showingOpenProduct"
      :default-name="searchQuery"
      :category-id="selectedCategoryId"
      :course-id="courseId"
      :catalog-id="catalogId"
      @close="showingOpenProduct = false"
      @save="saveOpenProduct"
    />
  </div>
</template>

<script setup lang="ts">
import { useFuse } from '@vueuse/integrations/useFuse'
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { storeToRefs } from 'pinia'
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { PromotionFilter } from '@last/core'
import { EmptyCase, LButton } from '@last/core-ui/paprika'

import CategoriesComponent from '@/components/ordering/Categories.vue'
import OpenProduct from '@/components/ordering/OpenProduct.vue'
import ProductList from '@/components/ordering/ProductList.vue'
import PromotionList from '@/components/ordering/PromotionList.vue'
import { useTabs } from '@/composables/useTabs'
import { managerConfirmation } from '@/plugins/managerConfirmation'
import { useAuthStore } from '@/store/auth'
import { useCatalogStore } from '@/store/catalog'
import { usePromotionsStore } from '@/store/promotions'
import { Promotion } from '@/types'

type Props = {
  customerPoints?: number
  customerUsedPromotions?: any[]
  courseId?: string | null
}

const props = withDefaults(defineProps<Props>(), {
  customerPoints: 0,
  customerUsedPromotions: () => [],
  courseId: null
})

const emit = defineEmits(['selectedProduct'])

const promotionsStore = usePromotionsStore()
const catalogStore = useCatalogStore()
const authStore = useAuthStore()

const { tabPromotions, getCustomerPromotions } = storeToRefs(promotionsStore)
const { getPromotions } = promotionsStore
const { catalogs, categories, products } = storeToRefs(catalogStore)
const { productsByCatalog } = catalogStore
const { locationId } = storeToRefs(authStore)

const route = useRoute()
const router = useRouter()
const tabId = computed(() => route.params.tabId as string)
const { tab, total, catalogId } = useTabs(tabId)

const selectedCategoryId = ref<string | null>(null)
const showingOpenProduct = ref(false)
const searchQuery = ref('')
const searchBarExpanded = ref<boolean>(false)

onMounted(() => {
  if (route.query.selectedCategoryId) {
    selectedCategoryId.value = route.query.selectedCategoryId as string
  }
})

const searchProducts = useFuse(
  searchQuery,
  productsByCatalog(catalogId.value),
  {
    fuseOptions: {
      keys: ['name'],
      threshold: 0.4,
      minMatchCharLength: 2
    }
  }
)

const productsList = computed(() => {
  if (searchQuery.value) {
    return searchProducts.results.value.map(r => r.item)
  } else {
    if (!selectedCategoryId.value) return []
    const productIds =
      categories.value[selectedCategoryId.value]?.products || []
    return productIds.map(id => products.value[id])
  }
})

const catalogCategories = computed(() => {
  if (!catalogId.value) return []
  return catalogs.value[catalogId.value]?.categories ?? []
})

const catalogProductsMap = computed(() => {
  return catalogCategories.value
    .flatMap(categoryId => categories.value[categoryId].products)
    .reduce(
      (res, productId) => {
        const product = products.value[productId]
        if (product) {
          res[product.organizationProductId || product.organizationComboId!] =
            product
        }
        return res
      },
      {} as Record<string, any>
    )
})

const isFastMode = computed(() => {
  return route.name === 'fastMode'
})

const arePromotionsAvailable = computed(() => {
  return !isFastMode.value && availablePromotions.value.length > 0
})

const availablePromotionsWithNoPoints = computed(() => {
  return availablePromotions.value
    .filter(promotion => !promotion.pointsExpense)
    .filter(promotion => ['2x1', 'percentage'].includes(promotion.discountType))
    .map(promotion => ({
      ...promotion,
      selected: (tabPromotions.value[route.params.tabId as string] || []).some(
        tabPromotion => tabPromotion.promotionId === promotion.id
      )
    }))
})

const availablePromotionsWithPoints = computed(() => {
  return availablePromotions.value
    .filter(promotion => promotion.pointsExpense)
    .filter(promotion => ['2x1', 'percentage'].includes(promotion.discountType))
    .map(promotion => ({
      ...promotion,
      selected: (tabPromotions.value[route.params.tabId as string] || []).some(
        tabPromotion => tabPromotion.promotionId === promotion.id
      ),
      disabled: promotion.pointsExpense > props.customerPoints
    }))
})

const availablePromotionProducts = computed(() => {
  return availablePromotions.value
    .filter(promotion => promotion.discountType === 'products')
    .flatMap(promotion => {
      let promotionProducts = promotion.products || []
      if (promotion.categories) {
        promotionProducts = promotion.categories
          .filter(categoryId => !!categories.value[categoryId])
          .flatMap(categoryId =>
            categories.value[categoryId].products.map(product => product.id)
          )
      }

      return promotionProducts
        .filter(id => catalogProductsMap.value[id])
        .map(id => ({
          ...catalogProductsMap.value[id],
          price: 0,
          disabled: promotion.pointsExpense > props.customerPoints,
          isValid: promotion.isValid,
          pointsExpense: promotion.pointsExpense
        }))
    })
})

const availablePromotions = computed<Promotion[][]>(() => {
  const customerId = tab.value.customerId
  const promotions = [
    ...getPromotions,
    ...(customerId ? getCustomerPromotions.value(customerId) : [])
  ]
    .filter(promotion =>
      ['2x1', 'percentage', 'products'].includes(promotion.discountType)
    )
    .filter(productInCatalog)

  const today = format(new Date(), 'eeee', { locale: enUS }).toLowerCase()

  return new PromotionFilter(
    total.value,
    locationId.value,
    today,
    tab.value.pickupType,
    customerId,
    props.customerUsedPromotions
  )
    .addValidation(promotions)
    .filter(p => p.isVisible)
})

async function newOpenProduct() {
  const hasPermission = await (managerConfirmation(
    'OPEN_PRODUCTS_CREATOR'
  ) as unknown as Promise<boolean>)
  if (hasPermission) {
    showingOpenProduct.value = true
  }
}

function saveOpenProduct(openProduct: any) {
  emit('selectedProduct', openProduct, openProduct.quantity)
  showingOpenProduct.value = false
}

function selectCategory(id: string) {
  selectedCategoryId.value = id ?? null
  if (selectedCategoryId.value !== route.query.selectedCategoryId) {
    router.replace({
      params: { tabId: tabId.value },
      query: { ...route.query, selectedCategoryId: id }
    })
  }
}

async function updateTabPromotionIntoTab(promotion: any) {
  await promotionsStore.updateTabPromotion({
    promotion,
    tabId: route.params.tabId as string
  })
}

function productInCatalog(promotion: any) {
  if (promotion.products) {
    return promotion.products.some((id: string) => catalogProductsMap.value[id])
  } else if (promotion.categories) {
    return promotion.categories.some((id: string) =>
      catalogCategories.value.includes(id)
    )
  } else {
    return false
  }
}
</script>
