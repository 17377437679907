<template>
  <edit-client-form
    v-if="isEditingCustomer"
    v-model:is-valid="formIsValid"
    :customer="customer"
    :mode="customer.id ? 'edition' : 'creation'"
    @change="onFormChange"
    @save="onFormSave"
    @cancel="isEditingCustomer = false"
  />
  <template v-if="!isEditingCustomer">
    <div class="flex mb-6">
      <div class="flex flex-1 items-center gap-3 min-w-0">
        <div
          class="h-16 w-16 rounded-full bg-r-400 font-body text-2xl text-n-0 flex items-center justify-center uppercase"
        >
          {{ initialName }}
        </div>
        <div class="flex flex-col flex-1 min-w-0">
          <div
            class="font-heading font-bold text-2xl text-ellipsis overflow-hidden whitespace-nowrap capitalize"
          >
            {{ customer.name }}
            {{ customer.surname }}
          </div>
          <div
            v-if="config.organizationConfig.promotions && customer.points"
            class="flex items-center gap-1 text-g-500"
          >
            <l-icon name="points" />
            <div class="font-heading font-bold">
              {{ $n(customer.points.actualPoints ?? 0, 'decimal') }}
            </div>
          </div>
        </div>
      </div>
      <div class="ml-4">
        <l-button size="small" icon="edit" @click="isEditingCustomer = true" />
      </div>
    </div>
    <div class="flex mb-4">
      <div class="flex flex-col font-body text-sm flex-1">
        <div class="text-n-400">
          {{ $t('new-delivery.client-email-label') }}
        </div>
        <div class="text-n-0">{{ customer.email || '-' }}</div>
      </div>
      <div class="flex flex-col font-body text-sm flex-1">
        <div class="text-n-400">
          {{ $t('new-delivery.client-phone-label') }}
        </div>
        <div class="text-n-0">
          {{ customer.phoneNumber || '-' }}
        </div>
      </div>
    </div>
    <div class="flex flex-col font-body text-sm">
      <div class="text-n-400">
        {{ $t('new-delivery.internal-note') }}
      </div>
      <div class="text-n-0">{{ customer.internalNote || '-' }}</div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, defineModel, ref, watch } from 'vue'

import { LButton, LIcon } from '@last/core-ui/paprika'

import { useConfigStore } from '@/store/config'
import { CustomerInfo } from '@/types'

import EditClientForm from './ClientForm.vue'

const configStore = useConfigStore()
const { config } = storeToRefs(configStore)

const customer = defineModel<CustomerInfo>('customer', {
  default: {}
})
const canContinue = defineModel<boolean>('canContinue', {
  default: true
})

const isEditingCustomer = ref<boolean>(false)
const formIsValid = ref<boolean>(true)

watch(
  customer,
  () => {
    if (!customer.value.id) {
      isEditingCustomer.value = true
      formIsValid.value = false
    } else {
      isEditingCustomer.value = false
      formIsValid.value = true
    }
  },
  { immediate: true }
)

watch(
  formIsValid,
  () => {
    canContinue.value = customer.value.id
      ? !isEditingCustomer.value
      : formIsValid.value
  },
  { immediate: true }
)

const initialName = computed(() => {
  if (!customer.value?.name && !customer.value?.surname) return ''
  const fullName = `${customer.value.name} ${customer.value.surname}`
  const initials = fullName
    .split(/[.,/ -]/)
    .map(n => n[0])
    .join('')
    .slice(0, 2)
  if (initials.length < 2) {
    return fullName.slice(0, 2)
  } else {
    return initials
  }
})

function onFormChange(_customer: CustomerInfo): void {
  customer.value = _customer
}

function onFormSave(_customer: CustomerInfo): void {
  customer.value = _customer
  isEditingCustomer.value = false
}
</script>
