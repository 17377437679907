import api from '@/api/fetch'
import { ShipmentJob } from '@/types/shipment'

export function getShipment(tabId: string) {
  return api
    .get<ShipmentJob | null>(`/tabs/${tabId}/shipment`)
    .then(res => res.data)
}

export function cancelShipment(tabId: string) {
  return api.post(`/tabs/${tabId}/shipment/cancel`, {})
}

export function requestShipment(tabIds: string[]) {
  return api.post('/tabs/requestShipment', {
    tabIds
  })
}
