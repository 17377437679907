<template>
  <input-wrapper
    :disabled="props.disabled"
    :size="props.size"
    :placeholder="placeholder ? placeholder : $t('search-bar.placeholder')"
    :model-value="model"
    @update:model-value="onSearch($event as string)"
  >
    <template #left>
      <l-icon name="search" class="text-n-800 dark:text-n-0 ml-4" />
    </template>
    <template #right>
      <transition name="appear">
        <div
          v-if="model"
          class="font-body text-sm text-v-300 mr-4 cursor-pointer"
          @click="clear()"
        >
          {{ $t('search-bar.clear') }}
        </div>
      </transition>
    </template>
  </input-wrapper>
</template>

<script setup lang="ts">
import { useDebounceFn, useThrottleFn } from '@vueuse/core'
import { defineModel } from 'vue'

import { LIcon } from '@last/core-ui/paprika'

import type { Size } from '../types'
import InputWrapper from './InputWrapper.vue'

const props = withDefaults(
  defineProps<{
    size?: Size
    disabled?: boolean
    debounceTime?: number
    throttleTime?: number
    placeholder?: string
  }>(),
  {
    size: 'small',
    disabled: false,
    debounceTime: 0,
    throttleTime: 0,
    placeholder: undefined
  }
)

const debouncedUpdate = useDebounceFn((value: string) => {
  model.value = value
}, props.debounceTime)

const throttleUpdate = useThrottleFn((value: string) => {
  model.value = value
}, props.throttleTime)

function onSearch(value: string) {
  if (props.debounceTime) {
    debouncedUpdate(value)
  } else if (props.throttleTime) {
    throttleUpdate(value)
  } else {
    model.value = value
  }
}

const model = defineModel<string>({
  default: ''
})

function clear() {
  model.value = ''
}
</script>

<style>
.appear-enter-active {
  animation: bounce-in 0.3s ease;
}
.appear-leave-active {
  animation: bounce-in 0.3s ease reverse;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }
  80% {
    opacity: 0.8;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
