import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, shallowRef } from 'vue'

import { getFloorplans } from '@/api/floorplan'
import { normalizeTables } from '@/normalizr'
import { useTabsStore } from '@/store/tabs'
import { Floorplan, Table } from '@/types'

export const useTablesStore = defineStore(
  'tables',
  () => {
    const floorplanIds = shallowRef<string[]>([])
    const floorplans = shallowRef<Record<string, Floorplan>>({})
    const tables = shallowRef<Record<string, Table>>({})

    const getTables = computed(() => (floorplanId: string) => {
      return floorplans.value[floorplanId].tables
        .map((tableId: string) => tables.value[tableId])
        .map(table => {
          const tabsStore = useTabsStore()
          const tabs = tabsStore.getByTableId(table.id)
          return {
            ...table,
            tabIds: tabs.map(tab => tab.id)
          }
        })
    })

    const allTables = computed(() => {
      return Object.values(tables.value).map(table => {
        const tabsStore = useTabsStore()
        const tabs = tabsStore.getByTableId(table.id)
        return {
          ...table,
          tabIds: tabs.map(tab => tab.id)
        }
      })
    })

    const getTabFloorplan = computed(() => (tabId: string) => {
      const tabsStore = useTabsStore()
      const tab = tabsStore.tabs[tabId]
      const tabTableId = tab?.tables[0]
      if (tabTableId) {
        const tabTable = tables.value[tabTableId]
        return floorplans.value[tabTable.floorplanId]
      }
      return null
    })

    async function refreshFloorplans() {
      const response = await getFloorplans()
      const data = normalizeTables(response)
      floorplanIds.value = data.result
      floorplans.value = (data.entities.floorplans ?? {}) as Record<
        string,
        Floorplan
      >
      tables.value = (data.entities.tables ?? {}) as Record<string, Table>
    }

    return {
      floorplanIds,
      floorplans,
      tables,
      getTables,
      allTables,
      getTabFloorplan,
      refreshFloorplans
    }
  },
  {
    persist: true
  }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTablesStore, import.meta.hot))
}
