<template>
  <div class="h-screen pt-safe pb-safe sm:bg-corner-gradient">
    <div class="px-6 sm:px-12 w-full max-w-[42rem] mx-auto pt-20 flex flex-col">
      <div class="text-n-0 text-3xl font-heading font-bold mb-8 text-center">
        {{ $t('locations.title') }}
      </div>
      <l-search-bar
        v-model="searchTerm"
        size="large"
        class="mb-4"
        :placeholder="$t('locations.search-placeholder')"
      />
      <transition-group
        tag="div"
        name="gridtransition"
        class="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mb-4"
      >
        <div
          v-for="location of filteredLocations"
          :key="location.id"
          class="text-n-0 bg-n-700 rounded-xl px-4 py-6 cursor-pointer"
          @click="selectLocationAndContinue(location)"
        >
          <div class="flex flex-col">
            <div class="flex flex-row mb-2 items-center">
              <div class="mr-3 w-4 h-4 flex-shrink-0 flex-grow-0">
                <l-icon size="small" name="store" />
              </div>
              <div class="flex-grow">
                {{ location.name }}
              </div>
              <div class="mr-3 w-4 h-4 flex-shrink-0 flex-grow-0">
                <l-icon size="small" name="bracket-right" />
              </div>
            </div>
            <div class="text-sm text-n-200">{{ location.address }}</div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { LIcon, LSearchBar } from '@last/core-ui/paprika'

import { addDevice, getDevices } from '@/api/device'
import { getLocations } from '@/api/locations'
import app from '@/app'
import { useAuthStore } from '@/store/auth'
import sync from '@/sync/service'
import type { Location } from '@/types'

const { selectLocation } = useAuthStore()
const router = useRouter()
const route = useRoute()

const locationId = computed(() => {
  return route.query.locationId as string
})

const locations = ref<Location[]>([])
const searchTerm = ref<string>('')

onMounted(() => {
  allLocations()
})

const filteredLocations = computed(() => {
  if (searchTerm.value) {
    return locations.value.filter(location =>
      location.name.toLowerCase().includes(searchTerm.value.toLowerCase())
    )
  }
  return locations.value
})

async function allLocations(): Promise<void> {
  locations.value = await getLocations()
  if (locationId.value) {
    const location = locations.value.find(
      location => location.id === locationId.value
    )
    if (!location) return
    selectLocationAndContinue(location)
  } else if (locations.value.length === 1) {
    selectLocationAndContinue(locations.value[0])
  }
}

async function selectLocationAndContinue(location: Location): Promise<void> {
  const platform = Capacitor.getPlatform()
  let isExtraDevice = false
  if (platform !== 'web') {
    const response = await getDevices(location.id)
    const deviceId = (await Device.getId()).identifier
    const alreadyRegisteredDeviceIds = response.ids
    isExtraDevice =
      !alreadyRegisteredDeviceIds.includes(deviceId) &&
      alreadyRegisteredDeviceIds.length > 0
  }
  if (isExtraDevice) {
    await onDeviceAdded(location)
  }
  selectLocation(location)
  sync.resync()
  router.push({ name: 'employees' })
}

async function onDeviceAdded(location: Location) {
  const platform = Capacitor.getPlatform()
  if (platform !== 'web') {
    const deviceInfo = await app.getDeviceInfo()
    try {
      await addDevice(deviceInfo)
    } catch {
      await router.push({
        name: 'overTheLimit'
      })
      return
    }
  }
  selectLocation(location)
  sync.resync()
  router.push({ name: 'employees' })
}
</script>

<style>
.gridtransition-enter-active {
  transition:
    transform 100ms ease,
    opacity 100ms ease-out;
}

.gridtransition-leave-active {
  transition:
    transform 100ms ease,
    opacity 100ms ease-in;
}
.gridtransition-enter-from,
.gridtransition-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
