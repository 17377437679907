<template>
  <div
    class="flex h-16 justify-between cursor-pointer border-r-[0.375rem] py-2 min-w-0"
    :class="{
      'opacity-30': status === 'closed',
      'bg-n-700 border-v-300 ': selected,
      'border-transparent': !selected
    }"
  >
    <div class="flex pr-2 min-w-0" :class="{ 'ml-6': offset }">
      <tab-title :tab-id="tabId" class="px-4" />
    </div>
    <div v-if="isCancelled || hasAlert" class="pr-4">
      <l-status
        v-if="isCancelled"
        status="error"
        size="small"
        class="capitalize"
      >
        {{ $t('tabs.cancelled') }}
      </l-status>
      <l-status
        v-if="hasAlert"
        status="pending"
        size="small"
        class="capitalize"
      >
        {{ $t('tabs.alert') }}
      </l-status>
    </div>
    <div v-else class="pr-4 text-n-0 text-xs">
      <div
        v-if="!tab.activationTime && tab.schedulingTime"
        class="flex flex-col items-end"
      >
        <div v-if="!isSchedulingTimeToday">
          {{ d(tab.schedulingTime, 'day') }}
        </div>
        <div>
          {{ d(tab.schedulingTime, 'time') }}
        </div>
      </div>
      <div v-else-if="tab.activationTime">
        {{ d(tab.activationTime, 'time') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isToday } from 'date-fns'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { LStatus } from '@last/core-ui/paprika'

import { useTabs } from '@/composables/useTabs'

import TabTitle from './TabTitle.vue'

interface Props {
  tabId: string
  selectedTabId?: string
  offset?: boolean
}

const props = defineProps<Props>()

const { d } = useI18n()

const { tab, isCancelled, hasAlert, status } = useTabs(() => props.tabId)

const isSchedulingTimeToday = computed(() => {
  return !!tab.value.schedulingTime && isToday(tab.value.schedulingTime)
})

const selected = computed(() => props.tabId === props.selectedTabId)
</script>
