import { Option } from '@last/core-ui/paprika/components/dropdown/types'

import i18n from '@/i18n'

const t = i18n.global.t

export type TableStates = 'empty' | 'occupied' | 'toPay' | 'reserved'

export type MenuValues =
  | 'printTicket'
  | 'moveAccount'
  | 'mergeTabs'
  | 'closeTable'
  | 'reservations'
  | 'moveReservation'

export const menuItems: Record<MenuValues, Option> = {
  ['printTicket']: {
    icon: 'printer',
    label: t('floorplan.print-ticket'),
    value: 'printTicket'
  },
  ['moveAccount']: {
    icon: 'double-arrow-sides',
    label: t('floorplan.move-account'),
    value: 'moveAccount'
  },
  ['mergeTabs']: {
    icon: 'double-arrow-inside',
    label: t('floorplan.merge-tabs'),
    value: 'mergeTabs'
  },
  ['closeTable']: {
    icon: 'close',
    label: t('floorplan.close-table'),
    value: 'closeTable'
  },
  ['reservations']: {
    icon: 'calendar',
    label: t('floorplan.reservations'),
    value: 'reservations'
  },
  ['moveReservation']: {
    icon: 'double-arrow-sides',
    label: t('floorplan.move-reservations'),
    value: 'moveReservation'
  }
}

export const mapTableStateToMenuItems: Record<TableStates, Option[]> = {
  ['empty']: [menuItems['reservations'], menuItems['mergeTabs']],
  ['occupied']: [
    menuItems['printTicket'],
    menuItems['moveAccount'],
    menuItems['mergeTabs'],
    menuItems['closeTable']
  ],
  ['reserved']: [
    menuItems['printTicket'],
    menuItems['moveReservation'],
    menuItems['mergeTabs'],
    menuItems['closeTable']
  ],
  ['toPay']: [
    menuItems['printTicket'],
    menuItems['moveAccount'],
    menuItems['mergeTabs'],
    menuItems['closeTable']
  ]
}
