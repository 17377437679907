<template>
  <div class="w-screen h-screen overflow-hidden flex flex-col">
    <top-bar :show-back="true" @back="goBack">
      <template #center>
        <div v-if="tab" class="flex font-heading font-bold text-xl">
          <span v-if="tab.code">
            <span class="font-bold">{{ tab.code }}</span>
            <span v-if="tab.tableName"> - {{ tab.tableName }}</span>
            <span v-else-if="tab.name"> - {{ tab.name }}</span>
          </span>
          <span v-else-if="tab.tableName">{{ tab.tableName }}</span>
          <span v-else>{{ tab.name }}</span>
        </div>
      </template>
    </top-bar>
    <div class="flex justify-center flex-1 min-h-0 pb-safe">
      <div
        class="px-6 md:px-0 md:w-2/3 w-full md:max-w-[33.5rem] pt-8 pb-6 flex flex-col h-full overflow-y-auto"
      >
        <div class="text-xl text-n-10 flex flex-row mb-8">
          <div class="flex-1 font-bold">{{ $t('tabs.total') }}</div>
          <div class="flex-none">{{ currency(currentBillPending) }}</div>
        </div>
        <charge-calculator
          class="mb-4 flex-1 !h-auto"
          :payment-methods="paymentMethods"
          :initial-pending="currentBillPending"
          keypad-mode="normal"
          @change-payment-method="selectPaymentMethod"
          @calculator-change="calculatorChange"
        />
        <l-button
          class="w-full mt-auto shrink-0"
          :disabled="isCharging || !canCharge"
          @click="() => charge()"
        >
          {{ $t('checkout.charge') }} {{ currency(calculatorValues.toPay) }}
        </l-button>
        <l-button class="w-full shrink-0" type="text" @click="goToCheckout">
          {{ $t('checkout.advanced-charge') }}
        </l-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { LButton } from '@last/core-ui/paprika'

import type { CalculatorPayload } from '@/components/Calculator/CalculatorUtils'
import ChargeCalculator from '@/components/ChargeCalculator/ChargeCalculator.vue'
import TopBar from '@/components/core/TopBar.vue'
import { useCheckout } from '@/composables/useCheckout'
import { useMoney } from '@/composables/useMoney'
import { useTabs } from '@/composables/useTabs'

const route = useRoute()
const router = useRouter()

const tabId = ref<string>(
  Array.isArray(route.params?.tabId)
    ? route.params.tabId[0]
    : route.params.tabId
)

const { tab } = useTabs(tabId)
const { currency } = useMoney()

const calculatorValues = ref<CalculatorPayload>({
  toPay: 0,
  pending: 0,
  change: 0,
  tip: 0
})

const {
  currentBill,
  paymentMethods,
  isCharging,
  canCharge,
  charge,
  selectPaymentMethod
} = useCheckout(tabId.value, 'fastCheckout', calculatorValues)

const currentBillPending = computed<number>(
  () => currentBill.value?.pending || 0
)

function calculatorChange(value: CalculatorPayload) {
  calculatorValues.value = value
}

function goToCheckout(): void {
  router.push({
    name: 'checkout',
    params: { tabId: tabId.value }
  })
}

function goBack() {
  router.go(-1)
}
</script>
