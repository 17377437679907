<template>
  <div class="overflow-y-scroll sm:bg-n-700 scrolling-touch pb-4 w-full">
    <div
      v-for="(bill, index) in bills"
      :key="bill.id"
      class="bg-n-600 rounded-xl mb-2 border relative cursor-pointer"
      :style="{ 'z-index': bills.length - index }"
      :class="{
        'border-n-600': selectedBillId !== bill.id,
        'border-v-300': selectedBillId === bill.id
      }"
      @click="selectBill(bill.id)"
    >
      <bills-list-item
        :bill="bill"
        :selected-bill-id="selectedBillId"
        :current-bill-id="currentBillId"
        :index="index"
        :total-bills="bills.length"
        @send-invoice="$emit('sendInvoice', $event)"
        @bill-selected="$emit('billSelected', $event)"
        @invalidate="$emit('invalidate', $event)"
        @generate-invoice="$emit('generateInvoice', $event)"
        @cancel-invoice="$emit('cancelInvoice', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { BillWithPayments } from '@/types'

import BillsListItem from './BillsListItem.vue'

type Props = {
  bills: BillWithPayments[]
  selectedBillId?: string
  currentBillId?: string
}

withDefaults(defineProps<Props>(), {
  bills: () => [],
  selectedBillId: '',
  currentBillId: ''
})

const emit = defineEmits<{
  billSelected: [string]
  invalidate: [string]
  generateInvoice: [string]
  cancelInvoice: [string]
  sendInvoice: [string]
}>()

function selectBill(id: string) {
  emit('billSelected', id)
}
</script>
