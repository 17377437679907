<template>
  <div
    class="flex flex-shrink-0 items-center"
    @click.stop="emit('update:model-value', !modelValue)"
  >
    <div
      class="rounded cursor-pointer flex-shrink-0 p-1"
      :class="[
        ...borderColor,
        ...bgColor,
        ...size,
        disabled ? 'opacity-50 pointer-events-none' : ''
      ]"
    >
      <div class="w-full h-full flex items-center justify-center">
        <l-icon v-if="half" name="minus" class="text-n-0" small />
        <l-icon v-else-if="modelValue" name="check" class="text-n-0" small />
      </div>
    </div>
    <div v-if="label" class="ml-2 cursor-pointer text-n-600 dark:text-n-0">
      {{ label }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { LIcon } from '@last/core-ui/paprika'

interface Props {
  modelValue: boolean
  small?: boolean
  disabled?: boolean
  half?: boolean
  label?: string
  error?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  small: false,
  disabled: false,
  half: false,
  error: false,
  label: ''
})

const bgColor = computed(() => {
  if (props.modelValue || props.half) {
    return ['bg-v-500 dark:bg-v-300']
  }
  if (props.error) {
    return ['bg-red/20']
  }
  return ['bg-n-0 dark:bg-transparent-gray']
})

const borderColor = computed(() => {
  if (props.modelValue || props.half) {
    return ['border-none']
  }
  if (props.error) {
    return ['border', 'border-red']
  }

  return ['border', 'border-n-300']
})

const size = computed(() => {
  if (props.small) {
    return ['w-4', 'h-4']
  }
  return ['w-6', 'h-6']
})

const emit = defineEmits(['update:model-value'])
</script>
