class BarcodeScanner {
  private timeoutHandler: number = 0
  private inputString: string = ''
  private listeners: Array<(barcode: string) => void> = []

  initialize() {
    document.addEventListener('keypress', this.keyup)
    if (this.timeoutHandler) {
      window.clearTimeout(this.timeoutHandler)
    }
    this.timeoutHandler = window.setTimeout(() => {
      this.inputString = ''
    }, 200)
  }

  onInput(callback: (barcode: string) => void) {
    this.listeners.push(callback)
  }

  close() {
    document.removeEventListener('keypress', this.keyup)
    this.listeners = []
  }

  /**
   * We use arrow function to bind the context of this to the class
   */
  keyup = (e: KeyboardEvent) => {
    if (this.timeoutHandler) {
      window.clearTimeout(this.timeoutHandler)
      this.inputString += e.key
    }
    this.timeoutHandler = window.setTimeout(() => {
      if (this.inputString.length <= 3) {
        this.inputString = ''
        return
      }
      this.listeners.forEach(callback => callback(this.inputString))
      this.inputString = ''
    }, 200)
  }
}

export default new BarcodeScanner()
