<template>
  <l-modal
    :title="$t('tab-selector.multiple-tabs-table')"
    :description="$t('tab-selector.select-tab')"
    @close="emit('close')"
  >
    <div class="overflow-y-scroll h-96">
      <div
        v-for="tab in tabsToSelect"
        :key="tab.id"
        class="px-5 py-8 mt-5 bg-n-700 rounded-xl cursor-pointer"
        @click="emit('tabSelected', tab.id)"
      >
        <div class="flex flex-row font-text uppercase items-center">
          <l-icon class="mr-1" small name="store" />
          <div class="mr-1 font-bold">
            {{ $d(tab.activationTime, 'time') }} -
          </div>
          <div class="flex-1">
            {{ tab.name || tab.tableName }}
          </div>
          <div class="text-v-300">
            <l-icon small name="bracket-right" />
          </div>
        </div>
        <div class="flex flex-row justify-between items-baseline">
          <div class="flex flex-row">
            <div v-if="tab.code" class="mr-1">{{ tab.code }} -</div>
            <div>
              {{
                $t('tab-selector.products', {
                  productsQuantity: productsQuantity[tab.id]
                })
              }}
            </div>
          </div>
          <div class="text-xl font-bold font-text">
            {{ currency(totals[tab.id]) }}
          </div>
        </div>
      </div>
    </div>
  </l-modal>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue'

import { LIcon, LModal } from '@last/core-ui/paprika'

import { useMoney } from '@/composables/useMoney'
import { useTabs } from '@/composables/useTabs'
import { TabProduct } from '@/types'

const props = withDefaults(
  defineProps<{
    tabIdsToSelect: string[]
  }>(),
  {
    tabIdsToSelect: () => []
  }
)

const { currency } = useMoney()

const useTabsByTabId = props.tabIdsToSelect.reduce(
  (res, tabId: string) => {
    const { tab, total, allProducts } = useTabs(tabId)
    res[tabId] = {
      tab,
      total,
      allProducts
    }
    return res
  },
  {} as Record<
    string,
    Pick<ReturnType<typeof useTabs>, 'tab' | 'total' | 'allProducts'>
  >
)

const emit = defineEmits<{
  close: []
  tabSelected: [tabId: string]
}>()

type Tab = ReturnType<typeof useTabs>['tab']['value']
const tabsToSelect = computed<Tab[]>(() =>
  props.tabIdsToSelect.map(tabId => useTabsByTabId[tabId].tab.value)
)

const totals = computed<Record<string, number>>(() =>
  props.tabIdsToSelect.reduce(
    (res, tabId) => {
      res[tabId] = useTabsByTabId[tabId].total.value
      return res
    },
    {} as Record<string, number>
  )
)

const products = computed(() =>
  props.tabIdsToSelect.reduce(
    (res, tabId) => {
      res[tabId] = useTabsByTabId[tabId].allProducts.value
      return res
    },
    {} as Record<string, TabProduct[]>
  )
)

const productsQuantity = computed(() => {
  return props.tabIdsToSelect.reduce(
    (res, tabId) => {
      const tabProducts = products.value[tabId]
      res[tabId] = tabProducts.reduce(
        (
          quantity: number,
          product: {
            quantity: number
          }
        ) => {
          return quantity + product.quantity
        },
        0
      )
      return res
    },
    {} as Record<string, number>
  )
})
</script>
