import { Capacitor } from '@capacitor/core'
import { nextTick } from 'vue'

import { useDialog } from '@last/core-ui/paprika'

import i18n from '@/i18n'
import { useConfigStore } from '@/store/config'
import { Config } from '@/types/config'

import { AdyenDataphone } from './adyenDataphone'
import { DevDataphone } from './devDataphone.js'
import { RedsysDataphone } from './redsysDataphone.js'

class Dataphone {
  private device: any

  constructor() {}

  async init(config: Config, preferredDataphoneId?: string) {
    const platform = Capacitor.getPlatform()
    try {
      const configStore = useConfigStore()

      if (!import.meta.env.PROD || platform === 'web') {
        this.device = new DevDataphone()
      } else if (configStore.currentAdyenDataphone) {
        this.device = new AdyenDataphone(configStore.currentAdyenDataphone)
      } else {
        if (!configStore.config.paymentMethods.includes('Dataphone')) return
        for (const dataphoneConfig of config.dataphoneConfigs) {
          if (
            dataphoneConfig.id === preferredDataphoneId ||
            (!preferredDataphoneId && dataphoneConfig.business) ||
            platform === 'web'
          ) {
            this.device =
              this.device ?? new RedsysDataphone(dataphoneConfig, platform)
            break
          }
        }
        if (!this.device) return
      }
      this.device.init()
    } catch {
      throw new Error('No dataphone capability')
    }
  }

  async charge({
    amount,
    paymentId,
    tabId
  }: {
    amount: number
    paymentId: string
    tabId: string
  }) {
    if (!this.initialized()) {
      throw new Error('Dataphone is not initialized')
    }
    let dialogInstance
    try {
      let cancelled = false
      const dialog = useDialog()
      dialogInstance = dialog({
        icon: 'loading-spinner',
        iconAnimation: 'animate-spin',
        title: i18n.global.t('payment-screen.payment-in-process'),
        content: i18n.global.t('payment-screen.check-the-dataphone'),
        mainLabel: i18n.global.t('payment-screen.cancel-operation'),
        dismissable: false,
        hiddenClose: true,
        onConfirm: () => {
          cancelled = true
        }
      })
      const metadata = await this.device.charge({ amount, paymentId, tabId })
      if (cancelled) {
        this.device.cancel({ paymentId, tabId })
        throw new Error('Payment cancelled')
      }
      await nextTick()
      dialogInstance?.close()
      return metadata
    } catch (e) {
      dialogInstance?.close()
      throw e
    }
  }

  async refund(payment: any, tabId: string) {
    if (this.initialized()) {
      const res = await this.device.refund(payment, tabId)
      return res
    } else {
      return { error: 'Not initialized' }
    }
  }

  initialized() {
    return this.device?.initialized ?? false
  }
}

export default new Dataphone()
