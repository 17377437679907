import moment from 'moment'

import { lastUtils } from '@last/core'

import { getReportZ } from '@/api/reports'
import { getLastShift, getOpenShiftMovements, getReportX } from '@/api/shift'
import { useNotifications } from '@/composables/useNotifications'
import Printer from '@/integrations/printer/printer'
import { useCatalogStore } from '@/store/catalog'
import { useConfigStore } from '@/store/config'
import { useTabsStore } from '@/store/tabs'
import { useTillStore } from '@/store/till'
import sync from '@/sync/service'
import TicketGenerator from '@/tickets/generator.js'
import KitchenOrderFormatter from '@/tickets/kitchenOrderFormatter.js'
import { EventName } from '@/types/events'

import i18n from '../../i18n'
import {
  BillWithPayments,
  CourierReport,
  KitchenOrder,
  Movement,
  Tab,
  Till,
  TillReport
} from '../../types'

function hasDirectPrint() {
  const { config, device } = useConfigStore()
  return !config.onlyMasterPrinting || device.mode === 'master'
}

const wakeupCalls: Record<
  string,
  {
    valuesToCheck: string[]
    meta: boolean
    number: boolean
    wakeUp: () => void
    [key: string]: boolean | any
  }
> = {}

function wakeupPrint(billId: string, value: string) {
  const call = wakeupCalls[billId]
  if (call) {
    call[value] = true
    if (call.valuesToCheck.every(val => call[val])) {
      call.wakeUp()
    }
  }
}

// https://stackoverflow.com/a/51843228
function wakeablePrintPromise(bill: BillWithPayments, valuesToCheck: string[]) {
  return new Promise(resolve => {
    wakeupCalls[bill.id] = {
      meta: !!bill.metadata,
      number: !!bill.number,
      valuesToCheck,
      wakeUp: () => resolve(true)
    }
  })
}

async function printKitchenOrder(order: KitchenOrder) {
  const { notifyInfo } = useNotifications()
  notifyInfo({
    title: i18n.global.t('notifications.sent-to-kitchen'),
    icon: 'kitchen'
  })
  if (!hasDirectPrint()) {
    sync.record('kitchenOrderAddedToPrintQueue', order.id)
    return
  }
  const tabsStore = useTabsStore()
  const tab = tabsStore.tabs[order.tabId]
  const catalog = useCatalogStore()
  const catalogId = catalog.getCatalogIdByTabVirtualBrandId(tab.virtualBrandId)
  const sortedCourses = catalog.catalogs[catalogId!]?.courses || []
  const config = useConfigStore()
  const printer = config.config.printers[order.printerId] || {}
  const scale = 4
  const canvas = await TicketGenerator.kitchenOrder(
    KitchenOrderFormatter.format(
      { ...order, tableName: tab.tableName },
      sortedCourses
    ),
    printer,
    scale
  )
  const image = canvas.toDataURL('image/png')
  let printedEvent = null
  printedEvent = {
    name: 'kitchenOrderPrinted',
    data: { orderId: order.id }
  } satisfies { name: EventName; data: any }
  Printer.printImage(order.printerId, image, order.copies, printedEvent)
}

async function printKitchenNote(courseLabel: string, tab: Tab) {
  const scale = 4
  const canvas = await TicketGenerator.kitchenNote(courseLabel, tab, scale)
  const image = canvas.toDataURL('image/png')

  const tabs = useTabsStore()
  const kitchenOrdersOnTab = tab.kitchenOrders.map(order => {
    return tabs.kitchenOrders[order]
  })

  const kitchenOrdersForCourse = kitchenOrdersOnTab.filter(order => {
    return order.versions.some(version => {
      return version.products.some(product => product.course === courseLabel)
    })
  })

  const printers = [
    ...new Set(
      kitchenOrdersForCourse.map(order => {
        return order.printerId
      })
    )
  ]

  printers.forEach(printerId => Printer.printImage(printerId, image, 1))
}

async function printCancelTabTicket(tab: Tab) {
  const scale = 4
  const canvas = await TicketGenerator.cancelTab(tab, scale)
  const image = canvas.toDataURL('image/png')
  const tabs = useTabsStore()
  const printers = [
    ...new Set(
      tab.kitchenOrders.map(order => {
        return tabs.kitchenOrders[order]?.printerId
      })
    )
  ]
  printers.map(printerId => Printer.printImage(printerId, image, 1))
}

async function printBill(bill: BillWithPayments, isPendingBill = false) {
  const { notifyInfo } = useNotifications()
  notifyInfo({
    title: i18n.global.t('notifications.printing-bill'),
    icon: 'ticket'
  })
  const { config, device } = useConfigStore()
  if (!hasDirectPrint() && !device.useSelfPrinter) {
    if (!isPendingBill) {
      sync.record('billAddedToPrintQueue', bill.id)
      return
    } else {
      sync.record('pendingBillAddedToPrintQueue', bill.tabId)
      return
    }
  }

  const valuesToCheck = []
  if (config.waitForMetaBeforePrintingBill && !bill.metadata)
    valuesToCheck.push('meta')
  if (config.organizationConfig.waitForNumberBeforePrintingBill && !bill.number)
    valuesToCheck.push('number')
  if (!isPendingBill && valuesToCheck.length > 0) {
    await Promise.race([
      wakeablePrintPromise(bill, valuesToCheck),
      lastUtils.sleep(5000)
    ])
    delete wakeupCalls[bill.id]
    const tabs = useTabsStore()
    const storedBill = tabs.getBillById(bill.id)
    bill = storedBill || bill
  }
  const options = {
    barcode: config.organizationConfig.barcodes,
    logoImageId: config.virtualBrands.find(v => v.id === bill.virtualBrandId)
      ?.imageId,
    isTaxExempt: config.legalEntity.isTaxExempt,
    taxExemptionReason: config.legalEntity.taxExemptionReason
  }
  let image = null
  const scale = 4
  const canvas = await TicketGenerator.bill(bill, options, scale)
  image = canvas.toDataURL('image/png')
  const printerId = getTargetPrinterId(bill.pickupType)
  let printedEvent = undefined
  if (!bill.printedTime && !isPendingBill) {
    printedEvent = {
      name: 'billPrinted',
      data: { billId: bill.id }
    } satisfies {
      name: EventName
      data: any
    }
  }
  Printer.printImage(printerId, image, config.billCopies, printedEvent)
}

async function printCourierReport(
  report: CourierReport & { total: number; courierName: string }
) {
  const scale = 4
  const canvas = await TicketGenerator.courierReport(report, scale)
  const image = canvas.toDataURL('image/png')

  const printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printZReport(report?: any) {
  const scale = 4
  if (!report) {
    report = await getReportZ()
  }
  const config = useConfigStore()
  const canvas = await TicketGenerator.zReport(report, config.config, scale)
  const image = canvas.toDataURL('image/png')

  const printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printRealTimeReport() {
  const { notifySuccess } = useNotifications()
  notifySuccess({
    title: i18n.global.t('notifications.real-time-report')
  })
  const today = moment().subtract(4, 'hours')
  const report = await getReportZ({
    startDate: today.format('YYYY-MM-DD'),
    endDate: today.format('YYYY-MM-DD')
  })
  const scale = 4
  const config = useConfigStore()
  const canvas = await TicketGenerator.realTimeReport(
    report,
    config.config,
    scale
  )
  const image = canvas.toDataURL('image/png')
  const printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printTillReport(till: Till, report: TillReport) {
  const { notifySuccess } = useNotifications()
  notifySuccess({
    title: i18n.global.t('notifications.till-report')
  })
  let response
  if (!report) {
    response = await getLastShift(till.id)
  }
  const scale = 4
  const canvas = await TicketGenerator.tillReport(
    report || response,
    till.name,
    scale
  )
  const image = canvas.toDataURL('image/png')
  const printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

function getTargetPrinterId(pickupType?: string) {
  let printerId
  const { config, device, currentAdyenDataphone } = useConfigStore()
  const till = useTillStore()
  if (pickupType) {
    if (config.deliveryPrinterId) {
      printerId = config.deliveryPrinterId
    } else {
      const deliveryTillId = config.deliveryTillId
      const cashTill = config.tills.cash.find(t => t.id === deliveryTillId)
      if (cashTill) {
        printerId = till.printerId
      }
    }
  }
  if (!printerId) {
    if (till.selectedCashTill && till.selectedCashTill.printerId) {
      printerId = till.selectedCashTill.printerId
    } else {
      printerId = config.defaultBillPrinter
    }
  }
  if (currentAdyenDataphone && device.useSelfPrinter) {
    printerId = 'self'
  }
  return printerId
}

async function printXReport(isPreview = false) {
  const { notifySuccess } = useNotifications()
  notifySuccess({ title: i18n.global.t('notifications.x-report') })
  const scale = 4
  const report = await getReportX()
  const config = useConfigStore()
  const canvas = await TicketGenerator.xReport(
    report,
    config.config,
    scale,
    isPreview
  )
  const image = canvas.toDataURL('image/png')
  const printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printPayInPayOut(movement: Movement) {
  const scale = 4
  const canvas = await TicketGenerator.payInPayOut(movement, scale)
  const image = canvas.toDataURL('image/png')
  const printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printShiftMovements(
  tillId: string,
  name: string,
  movements: Movement[]
) {
  const { notifySuccess } = useNotifications()
  notifySuccess({
    title: i18n.global.t('notifications.print-movements')
  })
  if (!movements) {
    const response = await getOpenShiftMovements(tillId)
    movements = response.movements
  }
  const scale = 4
  const config = useConfigStore()
  const canvas = await TicketGenerator.shiftMovements(
    { movements },
    name,
    config.config,
    scale
  )
  const image = canvas.toDataURL('image/png')
  const printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

async function printBankReceipt(payment: any) {
  if (!hasDirectPrint()) {
    sync.record('paymentAddedToPrintQueue', payment.id)
    return
  }
  const scale = 4
  const canvas = await TicketGenerator.bankReceipt(payment, scale)
  const image = canvas.toDataURL('image/png')
  const printerId = getTargetPrinterId()
  Printer.printImage(printerId, image, 1)
}

export default {
  printKitchenOrder,
  printKitchenNote,
  printCancelTabTicket,
  printBill,
  printZReport,
  printXReport,
  printTillReport,
  printPayInPayOut,
  printShiftMovements,
  printRealTimeReport,
  printCourierReport,
  printBankReceipt,
  wakeupPrint
}
