import api from '@/api/fetch'

export function getMenuAvailability() {
  return api.get('/menu-availability').then(res => res.data)
}

export function updateMenuAvailability(
  locationId: string,
  type: string,
  itemId: string,
  enabled: boolean
) {
  return api.put(`/locations/${locationId}/${type}/${itemId}`, { enabled })
}
