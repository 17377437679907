export default class PromotionFilter {
  constructor(
    expense,
    locationId,
    weekday,
    pickupType,
    customer,
    usedPromotions
  ) {
    this.expense = expense
    this.locationId = locationId
    this.weekday = weekday
    this.pickupType = pickupType
    this.customer = customer
    this.usedPromotions = usedPromotions
  }

  addValidation(promotions, doCustomerChecks = true) {
    return promotions.map(promotion => {
      return {
        ...promotion,
        isValid: this.isPromotionValid(promotion, doCustomerChecks),
        isVisible: this.isPromotionVisible(promotion)
      }
    })
  }

  isPromotionVisible(promotion) {
    if (!promotion) return false

    if ((promotion.locations?.length || 0) > 0) {
      if (!this.locationId || !promotion.locations.includes(this.locationId))
        return false
    }

    if (promotion.pickupType && promotion.pickupType !== this.pickupType)
      return false

    if ((promotion.weekdays?.length || 0) > 0) {
      if (!promotion.weekdays.includes(this.weekday)) return false
    }

    if (promotion.startTime && new Date() < new Date(promotion.startTime))
      return false

    if (promotion.endTime && new Date() > new Date(promotion.endTime))
      return false

    return true
  }

  isPromotionValid(promotion, doCustomerChecks = true) {
    if (!promotion) return false

    if (!this.isPromotionVisible(promotion)) return false

    if (this.expense < (promotion.minExpense || 0)) return false

    if (doCustomerChecks) {
      if (
        (promotion.customerIds?.length || 0) > 0 &&
        !promotion.customerIds.includes(this.customer)
      )
        return false

      if (promotion.oncePerPerson) {
        if (
          !this.customer ||
          (this.usedPromotions || []).includes(promotion.id)
        )
          return false
      }
    }

    return true
  }
}
