<template>
  <div class="h-full">
    <use-virtual-list
      :list="filteredNowTabsGrouped"
      :height="'100%'"
      :options="{ itemHeight: 64, overscan: 20 }"
    >
      <template
        #default="{ data: tabList, index }: { data: Tab[]; index: number }"
      >
        <div
          v-if="tabList.length === 1"
          :key="tabList[0].id"
          @click="$emit('selectTab', tabList[0].id, tabList[0].pickupType)"
        >
          <small-tab-row
            v-if="!fullScreen"
            :tab-id="tabList[0].id"
            :selected-tab-id="selectedTabId"
          />
          <large-tab-row
            v-else-if="
              !choosingForShipment || rowIsPickableForShipment(tabList[0])
            "
            :tab-id="tabList[0].id"
            :row-size="rowSize"
            :current-time="currentTime"
            :row-index="index"
            :is-selected="tabList[0].id === selectedTabId"
            :choosing-for-shipment="choosingForShipment"
            :picked-for-shipment="pickedTabsForShipment.includes(tabList[0].id)"
            @go-to-details="$emit('update:viewMode', 'splitScreen')"
            @shipment-pick-toggled="
              value => $emit('shipmentPickToggled', tabList[0].id, value)
            "
          />
        </div>
        <div v-else-if="!choosingForShipment" class="relative">
          <div
            class="flex items-center pl-4 pt-3 text-n-0"
            :class="{
              'bg-n-700': tabList.some(tab => tab.id === selectedTabId)
            }"
          >
            <l-icon name="link" size="small" />
            <span class="font-body text-xs ml-2">
              {{ $t('tabs.bundled-orders') }}
            </span>
          </div>
          <div
            v-for="tab in tabList"
            :key="tab.id"
            @click="$emit('selectTab', tab.id, tab.pickupType)"
          >
            <small-tab-row
              v-if="!fullScreen"
              :tab-id="tab.id"
              :selected-tab-id="selectedTabId"
              offset
            />
            <large-tab-row
              v-else
              :tab-id="tab.id"
              :row-size="rowSize"
              :current-time="currentTime"
              :is-selected="tab.id === selectedTabId"
              offset
              @go-to-details="$emit('update:viewMode', 'splitScreen')"
            />
          </div>
          <div class="w-[1px] bg-n-600 absolute top-0 bottom-0 mt-10 left-6" />
        </div>
      </template>
    </use-virtual-list>
  </div>
</template>

<script setup lang="ts">
import { UseVirtualList } from '@vueuse/components'
import { computed } from 'vue'

import { LIcon } from '@last/core-ui/paprika'

import LargeTabRow from '@/components/tabs/LargeTabRow.vue'
import SmallTabRow from '@/components/tabs/SmallTabRow.vue'
import { Tab } from '@/types'

const props = defineProps<{
  tabs: Tab[]
  pickedTabsForShipment: string[]
  choosingForShipment: boolean
  fullScreen: boolean
  filterClosed?: boolean
  selectedTabId?: string
  rowSize: string
  currentTime: Date
}>()

defineEmits(['selectTab', 'update:viewMode', 'shipmentPickToggled'])

const groupedTabs = computed(() => {
  return props.tabs.reduce(
    (res, tab) => {
      const key = tab.deliveryOrder?.shipmentId || tab.shopOrderId || 'null'
      if (!res[key]) res[key] = [tab]
      else res[key].push(tab)
      return res
    },
    {} as Record<string, Tab[]>
  )
})

const filteredNowTabsGrouped = computed(() => {
  return Object.keys(groupedTabs.value).flatMap(key => {
    if (key === 'null' || groupedTabs.value[key].length === 1) {
      return groupedTabs.value[key].map(tab => [tab])
    } else {
      return [groupedTabs.value[key]]
    }
  })
})

function rowIsPickableForShipment(tab: Tab) {
  return (
    tab.pickupType === 'ownDelivery' &&
    tab.open &&
    tab.deliveryOrder &&
    tab.deliveryOrder.status !== 'ON_DELIVERY' &&
    tab.deliveryOrder.status !== 'CLOSED' &&
    !tab.deliveryOrder.shipmentId
  )
}
</script>
