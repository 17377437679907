import api from '@/api/fetch.js'
import SocketFactory from '@/drivers/socket.js'
import PrinterQueue from '@/integrations/printer/printerQueue'
import pinia from '@/store'
import { useConfigStore } from '@/store/config'

import EscPos from './printers/escpos.js'

async function discoverPrinters(existingPrinters: any[]) {
  const config = useConfigStore(pinia)
  if (config.device.mode !== 'master') return
  const subnets = await getSubnets()
  const printerIps = await getPrintersOnSubnets(
    subnets,
    existingPrinters.filter(printer => printer.type !== 'usb')
  )
  const lanPrinters = Object.values(await getPrintersFromIps(printerIps))
  const usbPrinters = await getUSBPrinters(
    existingPrinters.filter(printer => printer.type === 'usb')
  )
  const printers = [...lanPrinters, ...usbPrinters]
  await addPrinters(printers)
}

async function getPrintersOnSubnets(
  subnets: string[],
  existingPrinters: any[]
) {
  try {
    const hosts = subnets.flatMap(subnet =>
      Array(256)
        .fill('')
        .map((_, index) => `${subnet}${index}`)
        .filter(
          ipAddress =>
            !existingPrinters.some(printer => printer.ip == ipAddress)
        )
    )
    const res = hosts.map(host => {
      return new Promise<string | null>(resolve => {
        const socket = SocketFactory.getSocket()
        socket.open(
          host,
          9100,
          () => {
            clearTimeout(to)
            socket.close()
            resolve(host)
          },
          () => {
            clearTimeout(to)
            socket.close()
            resolve(null)
          }
        )
        const to = setTimeout(() => {
          socket.close()
          resolve(null)
        }, 2000)
      })
    })

    return (await Promise.all(res)).filter(p => p) as string[]
  } catch (_) {
    return []
  }
}

async function getSubnets() {
  return Array(100)
    .fill('')
    .map((_v, index) => `192.168.${index}.`)
}

async function getPrintersFromIps(printerIps: string[]) {
  try {
    let printerId = 1
    const res: Record<string, any> = {}
    for (const printerIp of printerIps) {
      for (const type of ['EscPos']) {
        try {
          if (type === 'EscPos') {
            const p = new EscPos({
              type,
              address: printerIp
            })
            const status = (await p.getStatus())?.toString().charCodeAt(0)

            if (status) {
              res[printerIp] = {
                name: `Printer ${printerId}`,
                type,
                ip: printerIp
              }
              printerId++
            }
          }

          if (printerIp in res) {
            await printPrinterName({ ...res[printerIp] })
          }
        } catch (_) {
          return {}
        }
      }
    }
    return res
  } catch (_) {
    return {}
  }
}

async function getUSBPrinters(existingPrinters: any) {
  try {
    const { ipcRenderer } = window.require('electron')
    const printers: any[] = await ipcRenderer.invoke(
      'get-connected-usb-printers'
    )
    const filteredPrinters = printers
      .filter(printer => {
        if (
          existingPrinters.some(
            (ePrinter: any) => ePrinter.name === printer.name
          )
        )
          return false
        else if (printer.portName) {
          const offline =
            printer.attributes && printer.attributes.includes('OFFLINE')
          return (
            (printer.name === 'TICKETS' ||
              printer.portName.startsWith('USB')) &&
            !offline
          )
        } else if (printer.options) {
          return printer.options['device-uri'].startsWith('usb://')
        } else {
          return false
        }
      })
      .map(printer => ({
        name: printer.name,
        type: 'Usb'
      }))

    let res: any[] = []

    for (const printer of filteredPrinters) {
      try {
        await printPrinterName(printer)
        res = [...res, printer]
      } catch (_) {
        res = [...res]
      }
    }

    return res
  } catch (_) {
    return []
  }
}

async function printPrinterName(printer: any) {
  const printerQ = new PrinterQueue(printer)
  await printText(printerQ, printer)
}

async function printText(printerQueue: any, printer: any) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')!
  ctx.font = '28px Arial'
  ctx.fillText(`Name: ${printer.name}`, 10, 30)
  ctx.fillText(`Type:  ${printer.type}`, 10, 70)
  if (printer.ip) ctx.fillText(`IP: ${printer.ip}`, 10, 110)
  const imageData = canvas.toDataURL('image/png')
  const job = {
    type: 'printImage',
    image: imageData,
    time: new Date()
  }
  printerQueue.addJob(job)
}

async function addPrinters(printers: any) {
  const config = useConfigStore()
  await api.post('/printers', {
    printers,
    isMaster: config.device.mode === 'master'
  })
}

export default { discoverPrinters }
