import api from '@/api/fetch'
import { Company } from '@/types'

export function getCompanies(query: string) {
  return api
    .get<Company[]>('/companies', {
      query
    })
    .then(res => res.data)
}
