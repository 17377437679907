import { toRaw } from 'vue'

import app from '@/app'
import { useNotifications } from '@/composables/useNotifications'
import i18n from '@/i18n'
import { rum } from '@/monitoring'
import { DataphoneConfig } from '@/types/config'

export class RedsysDataphone {
  private initialized: boolean = false
  private initializing: boolean = false
  private config: DataphoneConfig
  private platform: string
  private ipcRenderer: any

  constructor(config: DataphoneConfig, platform: string) {
    this.initialized = false
    this.initializing = false
    this.config = config
    this.platform = platform
  }

  async init() {
    if (this.platform === 'web') {
      this.initialized = true
      return
    }
    if (!app.isElectron) return
    const { ipcRenderer } = window.require('electron')
    this.ipcRenderer = ipcRenderer
    if (!this.initializing) {
      this.initializing = true
      try {
        await this.ipcRenderer.invoke('dataphone:init', toRaw(this.config))
        this.initialized = true
      } catch (error) {
        rum.addError(error)
        const { notifyError } = useNotifications()
        notifyError({
          title: i18n.global.t('dataphone.bad-config-title'),
          description: i18n.global.t('dataphone.bad-config-subtitle')
        })
      }
    }
    this.initializing = false
  }

  async charge({ amount, paymentId }: { amount: number; paymentId: string }) {
    if (this.platform === 'web') return { chargeAuthorised: true }
    if (this.initialized) {
      const res = await this.ipcRenderer.invoke(
        'dataphone:charge',
        amount,
        paymentId
      )
      if (!res?.chargeAuthorised) {
        throw new Error('Dataphone charging failed')
      }
      return res?.metadata
    }
  }

  cancel(): Promise<void> {
    return this.ipcRenderer.invoke('dataphone:cancel')
  }

  async refund(payment: any) {
    if (this.platform === 'web') return {}
    const res = await this.ipcRenderer.invoke(
      'dataphone:refund',
      JSON.parse(JSON.stringify(payment))
    )
    return res
  }
}
