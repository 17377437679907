<template>
  <teleport to="#appRoot">
    <div class="fixed inset-0 bg-n-800">
      <full-screen :title="$t('tabs.merge-tab')" @close="$emit('close')">
        <floor-plan-with-navigation
          class="h-full"
          :wiggle-enabled="true"
          :blocked-tables="blockedTables"
          :selected-tables="selectedTables"
          @table-selected="tableSelected"
        />
        <template #footer>
          <l-button class="min-w-56" @click="merge">
            {{ $t('tabs.merge') }}
          </l-button>
        </template>
      </full-screen>
    </div>
    <tab-selector
      v-if="tabsToSelect.length > 1"
      :tab-ids-to-select="tabsToSelect"
      @tab-selected="selectedTab"
      @close="selectedTab"
    />
  </teleport>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import { LButton } from '@last/core-ui/paprika'

import FullScreen from '@/components/core/FullScreen.vue'
import FloorPlanWithNavigation from '@/components/tables/FloorPlanWithNavigation.vue'
import { useTablesStore } from '@/store/tables'
import { useTabsStore } from '@/store/tabs'
import { Table } from '@/types'

import TabSelector from '../tables/TabSelector.vue'

type Props = {
  tabId: string
}

const props = defineProps<Props>()

const emit = defineEmits(['close'])

const tabsStore = useTabsStore()
const tablesStore = useTablesStore()

const { mergeTabs, tabs } = tabsStore
const { allTables } = storeToRefs(tablesStore)

const selectedTabId = ref<string>('')
const selectedTable = ref<string | null>()
const tabsToSelect = ref<string[]>([])

const selectedTables = computed(() => {
  const tables = tabs[props.tabId].tables
  return selectedTable.value ? [...tables, selectedTable.value] : tables
})

const blockedTables = computed(() =>
  allTables.value
    .filter(table => table.tabIds.length == 0)
    .map(table => table.id)
)

function tableSelected(table: Table) {
  selectedTable.value = table.id
  if (table.tabIds.length > 1) {
    tabsToSelect.value = table.tabIds
  } else {
    selectedTabId.value = table.tabIds[0]
  }
}

function selectedTab(tabId?: string) {
  if (!tabId) {
    selectedTable.value = null
  } else {
    selectedTabId.value = tabId
  }
  tabsToSelect.value = []
}

function merge() {
  mergeTabs({ tabAId: props.tabId, tabBId: selectedTabId.value })
  emit('close')
}
</script>
