<template>
  <div class="w-full">
    <div
      class="items-center flex text-n-0 font-heading gap-2"
      :class="{ 'text-xl': size === 'big' }"
    >
      <tab-status :tab-id="tabId" />
      <div class="truncate">
        <span v-if="tab.code">
          <span class="font-bold">{{ tab.code }}</span>
          <span v-if="tab.tableName"> - {{ tab.tableName }}</span>
          <span v-else-if="tab.name"> - {{ tab.name }}</span>
        </span>
        <span v-else-if="tab.tableName">{{ tab.tableName }}</span>
        <span v-else>{{ tab.name }}</span>
      </div>
    </div>
    <div
      class="tab-source flex text-n-200"
      :class="{
        'text-xs pl-5': size === 'small',
        'text-lg pl-6': size === 'big'
      }"
    >
      {{ tab.source }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTabs } from '@/composables/useTabs'

import TabStatus from './TabStatus.vue'

interface Props {
  tabId: string
  size?: 'small' | 'big'
}
const props = withDefaults(defineProps<Props>(), {
  size: 'small'
})

const { tab } = useTabs(() => props.tabId)
</script>
