<template>
  <teleport to="#appRoot">
    <div class="fixed inset-0 bg-n-800">
      <full-screen
        :title="
          isMobile && previewBill
            ? $t('print-bills.bill') + ' ' + previewBill.code
            : $t('print-bills.title')
        "
        :show-back="!!previewBill"
        :show-close="!previewBill"
        @close="$emit('close')"
        @back="previewBill = undefined"
      >
        <div class="text-n-0 flex h-full p-4 gap-4">
          <div
            class="flex flex-col shrink grow-0"
            :class="[
              isMobile ? 'w-full' : 'basis-80',
              isMobile && previewBill ? 'hidden' : ''
            ]"
          >
            <tab-title
              :tab-id="tab.id"
              class="pb-4 border-b border-n-700 mb-4"
            />
            <div class="overflow-y-auto">
              <div
                v-for="bill in allBills"
                :key="bill.id"
                class="bg-n-700 px-4 py-6 font-heading font-medium text-base rounded-xl border border-transparent flex flex-col cursor-pointer mb-4"
                :class="{
                  'border-v-300': previewBill?.id === bill.id
                }"
                @click="previewBill = bill"
              >
                <div class="flex gap-2 items-center">
                  <l-icon name="ticket" />
                  {{
                    bill.customerCompany
                      ? $t('bill.invoice')
                      : $t('print-bills.bill')
                  }}
                  {{ bill.number }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="previewBill"
            class="flex flex-col sm:p-4 sm:bg-n-700 font-body text-xs flex-1 grow shrink rounded-lg sm:min-w-96 h-full relative"
          >
            <div class="overflow-y-auto">
              <div class="flex justify-center">
                <div class="flex-1 hidden sm:block"></div>
                <div v-if="canvasImage" class="bg-n-0 rounded-lg p-2">
                  <img :src="canvasImage" />
                </div>
                <div class="flex-1 min-w-48 hidden sm:block"></div>
              </div>
            </div>
            <div class="pt-4 sm:fixed bottom-8 right-8 flex flex-col gap-2">
              <l-button
                v-if="!previewBill.customerCompany"
                icon="ticket"
                type="secondary"
                class="w-full sm:w-auto"
                @click="showGenerateInvoice = true"
              >
                {{ $t('checkout.generate-invoice') }}
              </l-button>
              <template v-else>
                <l-button
                  icon="mail-close"
                  type="secondary"
                  class="w-full sm:w-auto"
                  @click="showSendInvoice = true"
                >
                  {{ $t('checkout.send-invoice') }}
                </l-button>
                <l-button
                  icon="close"
                  type="secondary"
                  class="w-full sm:w-auto"
                  @click="cancelInvoice(previewBill)"
                >
                  {{ $t('checkout.cancel-invoice') }}
                </l-button>
              </template>
              <l-button
                icon="printer"
                class="w-full sm:w-auto"
                @click="resendBill(previewBill)"
              >
                {{ $t('print-bills.resend') }}
              </l-button>
            </div>
          </div>
        </div>
      </full-screen>
    </div>
    <generate-invoice
      v-if="previewBill && showGenerateInvoice"
      :tab-id="tabId"
      :bill-id="previewBill.id"
      @new-bill-id="selectBill"
      @close="showGenerateInvoice = false"
    />
    <send-invoice
      v-if="previewBill && showSendInvoice"
      :bill-id="previewBill.id"
      @close="showSendInvoice = false"
    />
  </teleport>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { onMounted, ref, watch } from 'vue'

import { LButton, LIcon } from '@last/core-ui/paprika'

import { useTabs } from '@/composables/useTabs'
import TicketPrinter from '@/integrations/printer/ticketPrinter'
import { useConfigStore } from '@/store/config'
import { useTabsStore } from '@/store/tabs'
import TicketGenerator from '@/tickets/generator.js'
import type { BillWithPayments } from '@/types'

import FullScreen from '../core/FullScreen.vue'
import GenerateInvoice from '../invoices/GenerateInvoice.vue'
import SendInvoice from '../invoices/SendInvoice.vue'
import TabTitle from '../tabs/TabTitle.vue'

interface Props {
  tabId: string
}

const props = defineProps<Props>()

defineEmits<{
  close: []
}>()

const configStore = useConfigStore()
const { regenerateBill } = useTabsStore()
const { config } = storeToRefs(configStore)

const { allBills, tab } = useTabs(props.tabId)

const showGenerateInvoice = ref(false)
const showSendInvoice = ref(false)
const previewBill = ref<BillWithPayments>()
const canvasImage = ref<string>()

const isMobile = useBreakpoints(breakpointsTailwind).smallerOrEqual('sm')

onMounted(() => {
  if (isMobile.value) return
  previewBill.value = allBills.value[0]
})

function resendBill(bill: BillWithPayments) {
  TicketPrinter.printBill(bill)
}

function selectBill(billId: string) {
  previewBill.value = allBills.value.find(b => b.id === billId)
}

watch(previewBill, async bill => {
  if (bill) {
    const options = {
      barcode: config.value.organizationConfig.barcodes,
      logoImageId: config.value.virtualBrands.find(
        v => v.id === bill.virtualBrandId
      )?.imageId,
      isTaxExempt: config.value.legalEntity.isTaxExempt,
      taxExemptionReason: config.value.legalEntity.taxExemptionReason
    }
    const canvas = await TicketGenerator.bill(bill, options)
    canvasImage.value = canvas.toDataURL('image/png')
  }
})

async function cancelInvoice(bill: BillWithPayments) {
  const newBillId = regenerateBill({
    tabId: bill.tabId,
    billId: bill.id,
    customerCompany: undefined
  })
  selectBill(newBillId)
}
</script>
