<template>
  <full-screen :title="$t('pay-in-pay-out.title')" @close="router.go(-1)">
    <div class="flex flex-col justify-center items-center min-h-full pt-4">
      <div class="flex flex-col gap-4 w-[33rem]">
        <l-option-selector v-model="selectedTab" :options="tabs" />
        <calculator
          :initial-to-pay="total"
          keypad-mode="simple"
          display-mode="toPay"
          @change="onChange"
        />
        <l-field :label="$t('pay-in-pay-out.detail')">
          <l-input v-model="detail" type="textarea" class="h-20" />
        </l-field>
      </div>
    </div>
    <template #footer>
      <div class="w-[33rem] flex justify-center">
        <l-button class="w-1/2" :disabled="saving" @click="saveMovement()">
          {{
            selectedTab === 'payIn'
              ? $t('pay-in-pay-out.pay-in')
              : $t('pay-in-pay-out.pay-out')
          }}
          <span>
            {{ currency(total) }}
          </span>
        </l-button>
      </div>
    </template>
  </full-screen>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { LButton, LField, LInput, LOptionSelector } from '@last/core-ui/paprika'

import { useMoney } from '@/composables/useMoney'
import { useNotifications } from '@/composables/useNotifications'
import CashMachine from '@/integrations/cashmachine/cashmachine'
import TicketPrinter from '@/integrations/printer/ticketPrinter'
import { rum } from '@/monitoring'
import { useAuthStore } from '@/store/auth'
import { useTillStore } from '@/store/till'

import Calculator from '../Calculator/Calculator.vue'
import { CalculatorPayload } from '../Calculator/CalculatorUtils'
import FullScreen from '../core/FullScreen.vue'

const authStore = useAuthStore()
const tillStore = useTillStore()
const { t } = useI18n()
const { currency } = useMoney()
const router = useRouter()

const { currentEmployee } = storeToRefs(authStore)
const { addMovement } = tillStore
const { selectedCashTillIsStarted } = storeToRefs(tillStore)
const { notifySuccess } = useNotifications()

const tabs = [
  {
    label: t('pay-in-pay-out.pay-in'),
    value: 'payIn'
  },
  {
    label: t('pay-in-pay-out.pay-out'),
    value: 'payOut'
  }
]

const selectedTab = ref(tabs[0].value)
const total = ref(0)
const detail = ref()
const saving = ref(false)

const hasCashMachine = computed(() => {
  return CashMachine.isEnabled()
})

const machineAmount = computed(() => {
  return CashMachine.inputAmount.value
})

watch(machineAmount, amount => {
  total.value = Math.abs(amount)
})

onMounted(() => {
  if (!selectedCashTillIsStarted.value) {
    router.push({ name: 'employees' })
  }
  if (hasCashMachine.value) {
    total.value = machineAmount.value
  }
})

function onChange(event: CalculatorPayload) {
  total.value = event.toPay
}

async function saveMovement() {
  if (saving.value) return
  saving.value = true
  try {
    let method = 'cash'
    if (hasCashMachine.value) {
      await cashMachineAction()
      method = CashMachine.method!
    }
    if (total.value === 0) return
    const movement = {
      amount: total.value,
      detail: detail.value,
      movementType: selectedTab.value,
      method,
      tillId: currentEmployee.value?.tillEnabled
        ? (currentEmployee.value.tillId ?? null)
        : null
    }
    addMovement(movement)
    TicketPrinter.printPayInPayOut({
      date: new Date(),
      employee: currentEmployee.value?.name,
      ...movement
    })
    notifySuccess({
      title: t('pay-in-pay-out.movement-added')
    })
  } catch (error) {
    rum.addError(error)
    const { notifyError } = useNotifications()
    notifyError({
      title: (error as Error).message
    })
  } finally {
    saving.value = false
  }
}

async function cashMachineAction() {
  if (selectedTab.value === 'payIn') {
    total.value = Math.abs(await CashMachine.payIn(total.value))
  } else {
    // Pay out from the machine is negative, and the server needs a positive number
    total.value = Math.abs(await CashMachine.payOut(total.value))
  }
}
</script>
