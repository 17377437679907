import api from '@/api/fetch'
import { Customer } from '@/types'

export function getCustomer(phoneNumber: string): Promise<Customer> {
  return api
    .get('/customers', {
      phoneNumber
    })
    .then(res => res.data)
}

export function getCustomerPromotions(customerId: string) {
  return api.get(`/customer/${customerId}/promotions`).then(res => res.data)
}

export function updateCustomer(
  tabId: string,
  customerId: string,
  isHotel: boolean
) {
  return api
    .post(`/tabs/${tabId}/customer`, {
      identifier: customerId,
      isHotel
    })
    .then(res => res.data)
}
