import { PrinterConfig, PrinterDriver } from '../types.js'
import EscPosEncoder from './esc-pos-encoder.js'

export default class UsbPrinter implements PrinterDriver {
  private initialized = false
  private ipcRenderer: any
  private config: PrinterConfig

  constructor(config: PrinterConfig) {
    try {
      const { ipcRenderer } = window.require('electron')
      this.ipcRenderer = ipcRenderer
      this.config = config
      this.init()
    } catch {
      throw new Error('No USB capability')
    }
  }

  init() {
    if (this.initialized) return
    return this.ipcRenderer
      .invoke('usb-printer-init', this.config)
      .then(() => {
        this.initialized = true
      })
      .catch(() => {
        this.initialized = false
      })
  }

  async printImage(imageData: string) {
    await this.init()
    if (!this.initialized) return
    const encoder = new EscPosEncoder()
    const img = new Image()
    img.src = imageData
    const ipcRenderer = this.ipcRenderer
    const config = this.config
    img.onload = function () {
      const width = 504
      const ratio = width / img.width
      const height = Math.round((img.height * ratio) / 8) * 8
      const result = encoder
        .align('center')
        .image(img, width, height)
        .newline()
        .newline()
        .newline()
        .newline()
        .newline()
        .raw([0x1d, 0x56, 0x01])
        .encode()
      ipcRenderer.invoke('usb-printer-send-data', config.name, result)
    }
  }

  async openCashDrawer() {
    await this.init()
    if (!this.initialized) return
    const encoder = new EscPosEncoder()
    const result = encoder
      .raw([0x1b, 0x70, 0x00])
      .raw([0x1b, 0x70, 0x01])
      .encode()
    this.ipcRenderer.invoke('usb-printer-send-data', this.config.name, result)
  }
}
