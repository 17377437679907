<template>
  <input-wrapper
    v-bind="$props"
    v-model="model"
    :type="type"
    data-testid="l-password-input"
    @enter="$emit('enter')"
    @input="$emit('input', $event)"
  >
    <template #left>
      <l-icon
        v-if="leftIcon"
        :name="leftIcon"
        data-testid="l-password-input__left-icon"
        width="16"
        height="16"
        class="text-n-800 dark:text-n-200 ml-4"
      />
    </template>
    <template #right>
      <div class="cursor-pointer mr-4 w-4 h-4">
        <vue3-lottie
          ref="eyeAnim"
          width="16"
          height="16"
          :animation-data="EyeAnimation"
          :loop="false"
          :auto-play="false"
          :direction="animationDirection"
          data-testid="l-password-input__eye"
          @click="changeType"
          @on-animation-loaded="handleAnimationLoaded"
        />
      </div>
      <div v-if="rightIcon" class="cursor-pointer mr-4 w-4 h-4">
        <l-icon
          :name="rightIcon"
          data-testid="l-password-input__right-icon"
          width="16"
          height="16"
          class="text-n-800 dark:text-n-200"
        />
      </div>
    </template>
  </input-wrapper>
</template>

<script setup lang="ts">
import { computed, defineModel, onMounted, ref, useTemplateRef } from 'vue'
import { Vue3Lottie } from 'vue3-lottie'

import { LIcon, type LIconName } from '@last/core-ui/paprika'
import EyeAnimation from '@last/core-ui/paprika/assets/lottie/lottie-visibility-v-300.json'

import { type InputPropsWithoutType } from './inputTypes'
import InputWrapper from './InputWrapper.vue'

const passwordVisible = ref(false)
const eyeAnim = useTemplateRef('eyeAnim')
const animationDirection = ref<'forward' | 'reverse'>('forward')

type Props = InputPropsWithoutType & {
  initialVisible?: boolean
  leftIcon?: LIconName
  rightIcon?: LIconName
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  size: 'small',
  maxLength: 255,
  autocomplete: 'off',
  initialVisible: false,
  leftIcon: undefined,
  rightIcon: undefined,
  disabled: false,
  wrongValue: false
})

const model = defineModel<string | null>()

defineEmits(['enter', 'input'])

onMounted(() => {
  if (props.initialVisible) {
    passwordVisible.value = true
  }
})

function handleAnimationLoaded(): void {
  if (props.initialVisible) {
    eyeAnim.value!.playSegments([0, 16], true)
  }
}

function changeType() {
  passwordVisible.value = !passwordVisible.value
  if (passwordVisible.value) {
    animationDirection.value = 'forward'
    eyeAnim.value!.playSegments([0, 16], true)
  } else {
    animationDirection.value = 'reverse'
    eyeAnim.value!.playSegments([16, 0], true)
  }
}

const type = computed(() => (passwordVisible.value ? 'text' : 'password'))
</script>
